import { goBack, push } from "connected-react-router"
import { catOptions } from "fp-ts/lib/Array"
import { Lens } from "monocle-ts"
import React from "react"
import { toast } from "react-toastify"
import { Button, Col, Container, Input, Label, Row } from "reactstrap"
import Post from "../../@types/Post"
import { generateTagId, Tag } from "../../@types/Tag"
import Tool from "../../@types/Tool"
import { createPost, updatePost } from "../../services/PostService"
import { getTools } from "../../services/ToolService"
import store from "../../stores/store"
import { validateNonEmpty } from "../../util/ValidationUtils"
import ErrorMessage from "../atoms/ErrorMessage"
import MarkdownEditor from "../atoms/MarkdownEditor"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import TagInput from "../atoms/TagInput"

interface Props {
  post?: Post
}

interface State {
  id: string
  title: string
  body: string
  tags: Tag[]
  visible: boolean
  tagSuggestions: Tag[]
  errorMessages: string[]
  isLoading: boolean
}

class AddPostPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      id: props.post ? props.post.id : "",
      title: props.post ? props.post.title : "",
      body: props.post ? props.post.body! : "",
      tags: props.post ? props.post.tags : [],
      visible: props.post ? props.post.visible! : false,
      tagSuggestions: [],
      errorMessages: [],
      isLoading: false
    }

    this.handleBodyChange = this.handleBodyChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTagDelete = this.handleTagDelete.bind(this)
    this.handleTagAddition = this.handleTagAddition.bind(this)
  }

  componentDidMount() {
    this.getTools()
  }

  public render = () => (
    <div>
      <NoIndexHelmet />
      <Container className="add-post-container">
        <Row>
          <Col>
            <div className="add-form-title">
              <h2>{this.props.post ? "Edit Post" : "New Post"}</h2>
              {!this.props.post && (
                <div>
                  <h6>
                    Contribute to Made for Serverless submitting new posts.
                  </h6>
                </div>
              )}
            </div>
            {this.state.errorMessages.map((error, index) => (
              <ErrorMessage key={index}>{error}</ErrorMessage>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="add-form-text-fields-container">
            <Label for="title">Title</Label>
            <Input
              name="title"
              type="text"
              value={this.state.title}
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <Row>
          <Col className="add-form-text-fields-container">
            <Label for="body">Body</Label>
            <MarkdownEditor
              value={this.state.body}
              onChange={this.handleBodyChange}
            />
          </Col>
        </Row>
        <Row>
          <Col className="add-form-text-fields-container">
            <Label for="tags">Tags</Label>
            <TagInput
              tags={this.state.tags}
              suggestions={this.state.tagSuggestions}
              handleAddition={this.handleTagAddition}
              handleDelete={this.handleTagDelete}
            />
          </Col>
        </Row>
        <Row>
          <Col className="add-form-text-fields-container">
            <div className="checkbox-wrapper">
              <Label for="visible">Visible</Label>
              <Input
                className="ml-2"
                name="visible"
                type="checkbox"
                defaultChecked={this.state.visible}
                onChange={this.handleInputChange}
              />
            </div>
          </Col>
        </Row>
        <Row className="action-buttons-row">
          <Col>
            <div className="py-3">
              {this.props.post ? (
                <Button
                  className="add-form-button mr-2"
                  onClick={() => this.onUpdatePostClicked()}
                >
                  Update
                </Button>
              ) : (
                <Button
                  className="add-form-button mr-2"
                  onClick={() => this.onCreatePostClicked()}
                >
                  Submit
                </Button>
              )}
              <Button
                className="cancel-button"
                onClick={() => store.dispatch(goBack())}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )

  private handleBodyChange = (body: string) => {
    this.setState({ body: body })
  }

  private handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const fieldAccessor = Lens.fromProp<State>()(event.target
      .name as keyof State)
    const value =
      event.target.type == "checkbox"
        ? event.target.checked
        : event.target.value
    this.setState(fieldAccessor.set(value))
  }

  private handleTagDelete(i: number) {
    const { tags } = this.state
    this.setState({
      tags: tags.filter((tag: any, index: any) => index !== i)
    })
  }

  private handleTagAddition(newTag: Tag) {
    const tag =
      newTag.id == newTag.text
        ? {
            id: generateTagId(newTag.text),
            text: newTag.text
          }
        : newTag
    this.setState(state => ({ tags: [...state.tags, tag] }))
  }

  private getTools = async () => {
    const tools = await getTools({ visible: true })
    const suggestions = tools.data.map((tool: Tool) => {
      return { id: tool.id, text: tool.name }
    })
    this.setState({ tagSuggestions: suggestions })
  }

  private onUpdatePostClicked = async () => {
    const errors = this.getFormErrors()
    if (errors.length == 0) {
      const post = this.getPostItem()
      try {
        const response = await updatePost(post)
        this.showSuccessMessage(response.data, true)
        store.dispatch(push(`/post-detail/${post.id}`))
      } catch (error) {
        console.log(error)
        this.showErrorMessage()
      }
    } else {
      this.setState({ errorMessages: errors })
    }
  }

  private onCreatePostClicked = async () => {
    const errors = this.getFormErrors()
    if (errors.length == 0) {
      const post = this.getPostItem()
      try {
        const response = await createPost(post)
        this.showSuccessMessage(response.data, false)
        store.dispatch(push(`/posts`))
      } catch (error) {
        console.log(error)
        this.showErrorMessage()
      }
    } else {
      this.setState({ errorMessages: errors })
    }
  }

  private getFormErrors = () => {
    return catOptions([
      validateNonEmpty(this.state.title, "Title"),
      validateNonEmpty(this.state.body, "Body")
    ])
  }

  private getPostItem = () => {
    return {
      id: this.state.id,
      title: this.state.title,
      body: this.state.body,
      tags: this.state.tags,
      visible: this.state.visible
    }
  }

  private showSuccessMessage = (data: any, isUpdate: boolean) => {
    if (data) {
      toast.success(
        `The post has been ${
          isUpdate ? "updated" : "created, thanks for adding it"
        }!`
      )
    }
  }

  private showErrorMessage = () => {
    toast.error("Something went wrong, please try again")
  }
}

export default AddPostPage
