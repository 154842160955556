import { push } from "connected-react-router"
import React from "react"
import { connect } from "react-redux"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from "reactstrap"
import { Category } from "../../@types/Category"
import Routes from "../../@types/Routes"
import { setCategoryAndCleanOtherFilters } from "../../actions/catalogFilter"
import { getCategories } from "../../services/CategoryService"
import store from "../../stores/store"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"

interface State {
  dropdownOpen: boolean
  isLoading: boolean
  availableCategories: Category[]
}

interface Props {
  selectCategory: Function
}

class CategoryDropdown extends ReactTrackedComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
      isLoading: true,
      availableCategories: []
    }
  }

  componentDidMount() {
    getCategories()
      .then(categories => {
        const availableCategories: Category[] = categories.data
        this.setState({
          isLoading: false,
          availableCategories: availableCategories.filter(
            category => category.active
          )
        })
      })
      .catch(error => console.log(error))
  }

  render = () => {
    return this.state.isLoading ? (
      <Spinner className="spinner" type="grow" />
    ) : (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        direction="down"
        className="search-banner-dropdown"
      >
        <DropdownToggle caret>Browse the categories</DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header>Categories</DropdownItem>
          {this.getCategoryOptions(this.state.availableCategories)}
        </DropdownMenu>
      </Dropdown>
    )
  }

  private toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  private getCategoryOptions = (availableCategories: Category[]) =>
    availableCategories.map(category => (
      <DropdownItem
        onClick={this.searchByCategory.bind(this, category.id)}
        key={category.id}
      >
        {category.name}
      </DropdownItem>
    ))

  private searchByCategory = (category: string) => {
    this.fireEvent("Searcher", "Homepage search by category", category)
    this.props.selectCategory(category)
    store.dispatch(
      push({
        pathname: Routes.CATALOG
      })
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  selectCategory: (filter: string) =>
    dispatch(setCategoryAndCleanOtherFilters(filter))
})

export default connect(
  null,
  mapDispatchToProps
)(CategoryDropdown)
