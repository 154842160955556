import * as React from "react"
import { Col, Container, Row } from "reactstrap"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import FilterPostList from "../organisms/FilterPostList"
import ReactTrackedComponent from "./ReactTrackedComponent"
import { getAuthState } from "../../util/AuthenticationUtils"

interface Props {
  match: any
}

class PostDraftPage extends ReactTrackedComponent<Props> {
  public render = () => {
    const authState = getAuthState()
    return (
      <Container className="posts-page-container">
        <NoIndexHelmet />
        <Row>
          <Col>
            <h4 className="posts-title">Post Drafts</h4>
            <FilterPostList username={authState.username} visible={false} />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default PostDraftPage
