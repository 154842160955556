import React from "react"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import FilterToolList from "../organisms/FilterToolList"
import ReactTrackedComponent from "./ReactTrackedComponent"

class ReviewOverviewPage extends ReactTrackedComponent {
  render = () => (
    <div className="review-page-container">
      <NoIndexHelmet />
      <FilterToolList visible={false} />
    </div>
  )
}
export default ReviewOverviewPage
