import axios from "axios"
import { getJwtToken } from "../util/AuthenticationUtils"

const baseUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/comments`

export const getComments = async (toolId: string) => {
  return axios({
    method: "get",
    url: `${baseUrl}?toolId=${toolId}`,
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export const postComment = async (post: any) => {
  return axios({
    method: "post",
    url: `${baseUrl}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    data: post
  })
}

export const deleteComment = async (commentId: string) => {
  return axios({
    method: "delete",
    url: `${baseUrl}/${commentId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    }
  })
}

export const editComment = async (commentId: string, post: any) => {
  return axios({
    method: "put",
    url: `${baseUrl}/${commentId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    data: post
  })
}
