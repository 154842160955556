import { fromNullable } from "fp-ts/lib/Option"
import * as React from "react"
import { Col, Row } from "reactstrap"
import Post from "../../@types/Post"
import ErrorMessage from "../atoms/ErrorMessage"
import PostListItem from "../atoms/PostListItem"

interface Props {
  posts: Post[]
}

class PostList extends React.Component<Props> {
  public render = () =>
    fromNullable(this.props.posts)
      .map(posts => posts.map(this.renderPost))
      .getOrElse([renderError])

  private renderPost = (post: Post) => {
    return (
      <Row key={post.id}>
        <Col xs="12">
          <PostListItem post={post} />
        </Col>
      </Row>
    )
  }
}

const renderError = <ErrorMessage>Could not load posts</ErrorMessage>

export default PostList
