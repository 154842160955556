import axios from "axios"

export const getCategories = async () => {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/categories`,
    headers: {
      "Content-Type": "application/json"
    }
  })
}
