import * as React from "react"
import ReactGA from "react-ga"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Routes from "../../@types/Routes"
import { cleanCatalogFilter } from "../../actions/catalogFilter"
import { cleanPostFilter } from "../../actions/postFilter"
import store from "../../stores/store"
import ActionLink from "../atoms/ActionLink"

class Footer extends React.Component {
  public render = () => (
    <footer className="footer w-100">
      <Container className="footer-container">
        <Row>
          <Col
            xl={{ size: "auto", offset: 2 }}
            lg={{ size: "auto", offset: 1 }}
            md={{ size: 4, order: 1 }}
            className="footer-col"
          >
            <div className="footer-col-title">Rockets Platform</div>
            <div className="footer-col-content">
              <ul className="footer-sitemap">
                <li>
                  <Link to={Routes.HOME}>Home</Link>
                </li>
                <li>
                  <ActionLink
                    to={Routes.CATALOG}
                    onClickFunctions={[
                      () => store.dispatch(cleanCatalogFilter())
                    ]}
                  >
                    Catalog
                  </ActionLink>
                </li>
                <li>
                  <Link to={Routes.CATEGORIES}>Categories</Link>
                </li>
                <li>
                  <ActionLink
                    to={Routes.POSTS}
                    onClickFunctions={[() => store.dispatch(cleanPostFilter())]}
                  >
                    Posts
                  </ActionLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            lg={{ size: 2, order: 2 }}
            md={{ size: 4, order: 2 }}
            xs={{ order: 3 }}
            className="footer-col footer-col-logo"
          >
            <div className="footer-col-title col-logo">Development by:</div>
            <div>
              <ReactGA.OutboundLink
                eventLabel={"BoosterFramework clicked from footer"}
                to="https://www.booster.cloud/"
                target="_blank"
              >
                <img
                  className="footer-logo"
                  src="/images/BoosterFramework.png"
                />
              </ReactGA.OutboundLink>
            </div>
          </Col>
          <Col
            lg={{ size: 6, order: 3 }}
            md={{ size: 4, order: 3 }}
            xs={{ size: 12, order: 2 }}
            className="footer-col"
          >
            <div className="footer-col-title">Join our Newsletter:</div>
            <div className="newsletter-container">
              <MailchimpSubscribe
                url={process.env.REACT_APP_MAILCHIMP_URL as string}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
