import { Option, fromNullable, none } from "fp-ts/lib/Option"

export const validateNonEmpty = (
  target: string | undefined,
  name: string
): Option<string> => {
  if (!target || !target.trim()) {
    return fromNullable(`${name} cannot be empty`)
  }
  return none
}
