import React from "react"
import ReactGA from "react-ga"
import { Link } from "react-router-dom"
import StarRatingComponent from "react-star-rating-component"
import { Col, Container, Row, Spinner } from "reactstrap"
import Routes from "../../@types/Routes"
import Tool from "../../@types/Tool"
import ToolRating from "../../@types/ToolRating"
import { getTool } from "../../services/ToolService"
import Markdown from "../../util/Markdown"
import ErrorMessage from "../atoms/ErrorMessage"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ToolActionButtons from "../atoms/ToolActionButtons"
import ToolComments from "../organisms/ToolComments"
import ToolPosts from "../organisms/ToolPosts"
import ReactTrackedComponent from "./ReactTrackedComponent"
import UserToolRating from "../atoms/UserToolRating"

interface Props {
  match: any
}

interface State {
  tool?: Tool
  toolRating?: ToolRating
  isLoading: boolean
}

class ToolDetailPage extends ReactTrackedComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    this.getToolDetail()
  }

  public render = () => {
    return (
      <div>
        <NoIndexHelmet />
        {this.state.isLoading ? (
          <Spinner className="spinner" type="grow" />
        ) : this.state.tool ? (
          this.renderToolDetail(this.state.tool)
        ) : (
          <ErrorMessage>Tool not found</ErrorMessage>
        )}
      </div>
    )
  }

  private renderToolDetail = (tool: Tool) => {
    return (
      <Container className="tool-view-container my-4">
        <React.Fragment>
          <Row>
            <Col className="tool-back-to-search">
              <img
                className="tool-back-to-search-img"
                src="/images/back_arrow.svg"
              />
              <Link to={Routes.CATALOG}>Back to search</Link>
            </Col>
          </Row>
          <Row>
            <Col
              className="tool-view-properties"
              md={{ size: 3, offset: 3, order: 1 }}
              xs={{ order: 2 }}
            >
              <div className="tool-view-links-container">
                <ReactGA.OutboundLink
                  eventLabel={"Homepage Link clicked from DetailView"}
                  to={tool.homepageLink}
                  target="_blank"
                  className="btn tool-link"
                >
                  <img className="tool-link-img" src="/images/link_white.png" />
                  Homepage
                </ReactGA.OutboundLink>
                {tool.documentationLink && (
                  <ReactGA.OutboundLink
                    eventLabel={"Documentation Link clicked from DetailView"}
                    to={tool.documentationLink}
                    target="_blank"
                    className="btn tool-link"
                  >
                    <img
                      className="tool-link-img"
                      src="/images/folder_white.png"
                    />
                    Documentation
                  </ReactGA.OutboundLink>
                )}
              </div>
              <div className="tool-props-container non-editable">
                <h6 className="tool-props-title">Reviews</h6>
                {this.renderAverageRating(tool)}
              </div>

              <div className="tool-props-container">
                <h6 className="tool-props-title">Category</h6>
                <p className="tool-props-value">{tool.categoryName}</p>
              </div>
              <div className="tool-props-container">
                <h6 className="tool-props-title">Product</h6>
                <p className="tool-props-value">
                  {tool.openSource ? "Open source" : "Propietary"}
                </p>
              </div>
              {tool.free && (
                <div className="tool-props-container">
                  <p className="tool-props-value tool-free">Free</p>
                </div>
              )}
              <UserToolRating
                toolId={tool.id}
                refreshToolDetail={this.getToolDetail}
              />
              {this.renderButtons(tool)}
            </Col>
            <Col
              className="tool-view-descriptions"
              md={{ size: 6, order: 2 }}
              xs={{ order: 1 }}
            >
              <div className="tool-view-title">{tool.name}</div>
              <div className="tool-view-short-description">
                {tool.shortDescription}
              </div>
              <div className="tool-view-description">
                <Markdown>{tool.description}</Markdown>
              </div>
              <ToolComments tool={tool} />
              <ToolPosts tool={tool} />
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    )
  }

  private renderAverageRating = (tool: Tool) => {
    return (
      <div className="average-rating-container">
        <StarRatingComponent
          name="rate"
          starCount={5}
          starColor="#ffb400"
          emptyStarColor="#D1D1D1"
          value={tool.rating ? tool.rating : 0}
        />
        {tool.ratingCount ? (
          <span className="average-rating-count-label">
            ({tool.ratingCount})
          </span>
        ) : (
          ""
        )}
      </div>
    )
  }

  private renderButtons = (tool: Tool) => {
    return <ToolActionButtons tool={tool} />
  }

  private getToolDetail = () => {
    const toolId = this.props.match.params.id
    getTool(toolId)
      .then(response => {
        this.setState({
          tool: response.data,
          isLoading: false
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false })
      })
  }
}

export default ToolDetailPage
