import Amplify, { Auth } from "aws-amplify"
import "bootstrap/dist/css/bootstrap.css"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Spinner } from "reactstrap"
import NoIndexHelmet from "./components/atoms/NoIndexHelmet"
import App from "./components/pages/Main"
import * as serviceWorker from "./serviceWorker"
import store from "./stores/store"
import { getAuthStateFromCognitoUser } from "./util/AuthenticationUtils"

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_IMAGES_S3_BUCKET,
            region: process.env.REACT_APP_AWS_REGION,
        }
    }
})

const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
)

const loadingJsx = (
  <div>
    <NoIndexHelmet />
    <Spinner className="spinner" type="grow" />
  </div>
)

let hasRendered = false

const renderApplication = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"))
    hasRendered = true
  }
}

Auth.currentAuthenticatedUser()
  .then(state => {
    store.dispatch({
      type: "UPDATE_AUTHENTICATION_STATUS",
      auth: getAuthStateFromCognitoUser(state)
    })
    renderApplication()
  })
  .catch(_ => renderApplication())

ReactDOM.render(loadingJsx, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
