import * as React from "react"
import ErrorMessage from "../atoms/ErrorMessage"
import { Container } from "reactstrap"
import ReactTrackedComponent from "./ReactTrackedComponent";

class ErrorPage extends ReactTrackedComponent {
  public render = () => (
    <Container className="py-5">
      <ErrorMessage>Page not found</ErrorMessage>
    </Container>
  )
}

export default ErrorPage
