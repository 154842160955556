import React from "react"
import { Col, Row } from "reactstrap"
import { Category } from "../../@types/Category"
import { getCategories } from "../../services/CategoryService"
import CategoryGridItem from "../molecules/CategoryGridItem"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"
import { Spinner } from "reactstrap"

interface State {
  isLoading: boolean
  categories: Category[]
}

class CategoryGrid extends ReactTrackedComponent<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: true,
      categories: []
    }
  }

  componentDidMount() {
    getCategories()
      .then(categories => {
        this.setState({
          isLoading: false,
          categories: categories.data
        })
      })
      .catch(error => console.log(error))
  }

  public render = () => (
    <div className="category-grid-container">
      {this.state.isLoading ? (
        <Spinner className="spinner" type="grow" />
      ) : (
        <Row className="equal-height-cards">
          {this.state.categories.map(category => (
            <Col xs="12" sm="6" lg="4" key={category.id}>
              <CategoryGridItem
                key={category.id}
                category={category}
                enabled={category.active}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}

export default CategoryGrid
