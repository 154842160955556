import * as React from "react"
import { connect } from "react-redux"
import { FormGroup, Input, Spinner } from "reactstrap"
import { Category } from "../../@types/Category"
import { setCategoryFilter } from "../../actions/catalogFilter"
import { getCategories } from "../../services/CategoryService"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"

interface Props {
  category: string
  selectCategory: Function
}

interface State {
  isLoading: boolean
  availableCategories: Category[]
}

class CategorySelect extends ReactTrackedComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isLoading: true,
      availableCategories: []
    }
  }

  componentDidMount() {
    getCategories()
      .then(categories => {
        const availableCategories: Category[] = categories.data
        this.setState({
          isLoading: false,
          availableCategories: availableCategories.filter(
            category => category.active
          )
        })
      })
      .catch(error => console.log(error))
  }

  public render = () => (
    <div className="category-select-container">
      {this.state.isLoading ? (
        <Spinner className="spinner-inline" type="grow" />
      ) : (
        <FormGroup>
          <Input
            name="category"
            type="select"
            onChange={this.onChangeCategoryHandler}
            value={this.props.category}
          >
            {this.getCategoryOptions(this.state.availableCategories)}
          </Input>
        </FormGroup>
      )}
    </div>
  )

  private getCategoryOptions = (availableCategories: Category[]) => {
    const categoryOptions = []
    categoryOptions.push(
      <option value="" key="">
        All Categories
      </option>
    )
    categoryOptions.push(
      availableCategories.map(category => (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      ))
    )
    return categoryOptions
  }

  private onChangeCategoryHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const categoryValue = event.target.value != "" ? event.target.value : null
    this.props.selectCategory(categoryValue)
    this.fireEvent("Searcher", "Category search", event.target.value)
  }
}

const mapStateToProps = (state: any) => ({
  category: state.catalogFilter.category
})

const mapDispatchToProps = (dispatch: any) => ({
  selectCategory: (filter: string) => dispatch(setCategoryFilter(filter))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySelect)
