import React from "react"
import { Redirect, Route } from "react-router-dom"
import { getAuthState, validRole } from "../../util/AuthenticationUtils"
import Routes from "../../@types/Routes"

export const SecuredRoute = ({ component: C, path, ...rest }: any) => {
  const routeComponent = (props: any) => {
    const authState = getAuthState()
    const roles = { ...rest.allowedRoles }["roles"]
    return authState && authState.isAuthenticated ? (
      validRole(roles) ? (
        <C {...props} {...rest} />
      ) : (
        <Redirect to={{ pathname: Routes.HOME }} />
      )
    ) : (
      <Redirect to={{ pathname: Routes.LOGIN }} />
    )
  }

  return <Route path={path} render={routeComponent} />
}
