import * as React from "react"
import { default as ReactCookieBanner } from "react-cookie-banner"

const CookieBanner = () => (
  <ReactCookieBanner
    message="We have placed cookies on your computer to help make this website better. You can change your cookie settings at any time. Otherwise, we'll assume you're OK to continue."
    onAccept={() => {}}
    cookie="user-has-accepted-cookies"
    className="cookie-banner"
    disableStyle={true}
    dismissOnScroll={false}
    dismissOnClick={false}
    buttonMessage="Don't show this message again"
  />
)

export default CookieBanner
