import * as React from "react"
import { FormGroup, Input } from "reactstrap"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"

interface Props {
  searchString: string
  selectSearchString: Function
}

class SearchBar extends ReactTrackedComponent<Props> {
  public render = () => (
    <div className="search-bar-container">
      <FormGroup>
        <Input
          type="text"
          name="Search"
          placeholder="Search"
          value={this.props.searchString ? this.props.searchString : ""}
          onChange={this.onChangeTextHandler}
        />
      </FormGroup>
    </div>
  )

  private onChangeTextHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchStringValue =
      event.target.value != "" ? event.target.value : null
    this.props.selectSearchString(searchStringValue)

    this.fireEvent("Searcher", "Text search", event.target.value)
  }
}

export default SearchBar
