import { Option, none, fromNullable } from "fp-ts/lib/Option"
import React from "react"
import { Spinner } from "reactstrap"
import ErrorMessage from "../atoms/ErrorMessage"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ReactTrackedComponent from "./ReactTrackedComponent"
import Post from "../../@types/Post"
import AddPostPage from "./AddPostPage"
import { getPost } from "../../services/PostService"

interface Props {
  match: any
}

interface State {
  post: Option<Post>
  isLoading: boolean
}

class EditPostPage extends ReactTrackedComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      post: none,
      isLoading: true
    }
  }

  componentDidMount() {
    this.getPostDetail()
  }

  render = () => {
    return (
      <div>
        <NoIndexHelmet />
        {this.state.isLoading ? (
          <Spinner className="spinner" type="grow" />
        ) : this.state.post.isSome() ? (
          <AddPostPage post={this.state.post.value} />
        ) : (
          <ErrorMessage>Error trying to edit the post</ErrorMessage>
        )}
      </div>
    )
  }

  private getPostDetail = async () => {
    const postId = this.props.match.params.id
    try {
      const postResponse = await getPost(postId)
      this.setState({
        post: fromNullable(postResponse.data),
        isLoading: false
      })
    } catch (err) {
      console.log(err)
      this.setState({ isLoading: false })
    }
  }
}

export default EditPostPage
