enum Routes {
  HOME = "/",
  LOGIN = "/login",
  SIGNUP = "/signup",
  FORGOT_PASSWORD = "/forgot-password",
  CATEGORIES = "/categories",
  CATALOG = "/catalog",
  SHOW_TOOL = "/tool-detail/",
  ADD_TOOL = "/add-tool",
  EDIT_TOOL = "/edit-tool/",
  REVIEW_TOOLS = "/review",
  POSTS = "/posts",
  SHOW_POST = "/post-detail/",
  ADD_POST = "/add-post",
  EDIT_POST = "/edit-post/",
  POST_DRAFTS = "/post-drafts"
}

export default Routes
