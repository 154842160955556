import { goBack, push } from "connected-react-router"
import { catOptions } from "fp-ts/lib/Array"
import { Lens } from "monocle-ts"
import React from "react"
import { toast } from "react-toastify"
import { Button, Col, Container, Input, Label, Row, Spinner } from "reactstrap"
import { Category } from "../../@types/Category"
import Routes from "../../@types/Routes"
import Tool from "../../@types/Tool"
import { getCategories } from "../../services/CategoryService"
import { createTool, updateTool } from "../../services/ToolService"
import store from "../../stores/store"
import { getAuthState, isAdmin } from "../../util/AuthenticationUtils"
import { validateNonEmpty } from "../../util/ValidationUtils"
import ErrorMessage from "../atoms/ErrorMessage"
import MarkdownEditor from "../atoms/MarkdownEditor"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ReactTrackedComponent from "./ReactTrackedComponent"

interface Props {
  tool?: Tool
}

interface State {
  id: string
  name: string
  shortDescription: string
  description: string
  homepageLink: string
  documentationLink?: string
  free: boolean
  openSource: boolean
  category?: string
  visible: boolean
  errorMessages: string[]
  isLoading: boolean
  categories: Category[]
}

class AddToolPage extends ReactTrackedComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      id: props.tool ? props.tool.id : "",
      name: props.tool ? props.tool.name : "",
      shortDescription: props.tool ? props.tool.shortDescription : "",
      description: props.tool ? props.tool.description : "",
      homepageLink: props.tool ? props.tool.homepageLink : "",
      documentationLink:
        props.tool && props.tool.documentationLink
          ? props.tool.documentationLink
          : "",
      visible: props.tool ? props.tool.visible : false,
      free: props.tool ? props.tool.free : false,
      openSource: props.tool ? props.tool.openSource : false,
      category:
        props.tool && props.tool.category ? props.tool.category : "OTHERS",
      errorMessages: [],
      isLoading: true,
      categories: []
    }
  }

  componentDidMount() {
    getCategories()
      .then(categories => {
        this.setState({
          isLoading: false,
          categories: categories.data
        })
      })
      .catch(error => console.log(error))
  }

  public render = () => {
    const categoryOptions = this.state.categories.map(category => (
      <option key={category.id} value={category.id}>
        {category.name}
      </option>
    ))
    return (
      <div>
        <NoIndexHelmet />
        {this.state.isLoading ? (
          <Spinner className="spinner" type="grow" />
        ) : (
          <Container className="add-tool-container">
            <Row>
              <Col md={{ size: 9, offset: 3 }}>
                <div className="add-form-title">
                  <h2>{this.props.tool ? "Edit tool" : "New tool"}</h2>
                  {!this.props.tool && (
                    <div>
                      <h6>
                        Contribute to Made for Serverless catalog submitting new
                        tools.
                      </h6>
                      <h6>
                        All submissions will be reviewed by our core team to
                        ensure the catalog quality
                      </h6>
                    </div>
                  )}
                </div>
                {this.state.errorMessages.map((error, index) => (
                  <ErrorMessage key={index}>{error}</ErrorMessage>
                ))}
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
                <div className="add-form-text-fields-container pl-lg-0 pl-xl-4">
                  <Label for="name">Name</Label>
                  <Input
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />
                  <Label for="shortDescription">Short Description</Label>
                  <Input
                    name="shortDescription"
                    type="text"
                    value={this.state.shortDescription}
                    onChange={this.handleInputChange}
                  />
                  <Label for="description">Description</Label>
                  <MarkdownEditor
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                  />
                </div>
              </Col>
              <Col md={{ size: 3, offset: 3, order: 1 }} xs={{ order: 2 }}>
                <div className="add-form-text-fields-container">
                  <Label for="category">Category</Label>
                  <Input
                    name="category"
                    type="select"
                    value={this.state.category}
                    onChange={this.handleInputChange}
                  >
                    {categoryOptions}
                  </Input>
                  <img className="tool-link-img" src="/images/link_black.svg" />
                  <Label for="homepageLink">Homepage Link</Label>
                  <Input
                    name="homepageLink"
                    type="text"
                    value={this.state.homepageLink}
                    onChange={this.handleInputChange}
                  />
                  <img
                    className="tool-link-img d-sm-inline d-md-none d-lg-inline"
                    src="/images/folder_black.svg"
                  />
                  <Label for="documentationLink">Documentation Link</Label>
                  <Input
                    name="documentationLink"
                    type="text"
                    value={this.state.documentationLink}
                    onChange={this.handleInputChange}
                  />
                  {isAdmin(getAuthState()) && (
                    <div className="checkbox-wrapper">
                      <Label for="visible">Visible</Label>
                      <Input
                        className="ml-2"
                        name="visible"
                        type="checkbox"
                        defaultChecked={this.state.visible}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  )}
                  <div className="checkbox-wrapper">
                    <Label for="free">Free</Label>
                    <Input
                      className="ml-2"
                      name="free"
                      type="checkbox"
                      defaultChecked={this.state.free}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="checkbox-wrapper">
                    <Label for="openSource">Open Source</Label>
                    <Input
                      className="ml-2"
                      name="openSource"
                      type="checkbox"
                      defaultChecked={this.state.openSource}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="py-3">
                  {this.props.tool ? (
                    <Button
                      className="add-form-button mr-2"
                      onClick={() => this.onUpdateToolClicked()}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="add-form-button mr-2"
                      onClick={() => this.onCreateToolClicked()}
                    >
                      Submit
                    </Button>
                  )}
                  <Button
                    className="cancel-button"
                    onClick={() => store.dispatch(goBack())}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    )
  }

  private onUpdateToolClicked = async () => {
    const errors = this.getFormErrors()
    if (errors.length == 0) {
      const tool = this.getUpdateToolItem()
      updateTool(tool)
        .then((response: any) => {
          this.showSuccessMessage(response.data, true)
          this.state.visible
            ? store.dispatch(push(`${Routes.SHOW_TOOL}${tool.id}`))
            : store.dispatch(push(`/review`))
        })
        .catch((error: any) => {
          console.log(error)
          this.showErrorMessage()
        })
    } else {
      this.setState({ errorMessages: errors })
    }
  }

  private onCreateToolClicked = async () => {
    const errors = this.getFormErrors()
    if (errors.length == 0) {
      const tool = this.getCreateToolItem()
      createTool(tool)
        .then((response: any) => {
          this.showSuccessMessage(response.data, false)
          store.dispatch(push(`/catalog`))
        })
        .catch((error: any) => {
          console.log(error)
          this.showErrorMessage()
        })
    } else {
      this.setState({ errorMessages: errors })
    }
  }

  private handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const fieldAccessor = Lens.fromProp<State>()(event.target
      .name as keyof State)
    const value =
      event.target.type == "checkbox"
        ? event.target.checked
        : event.target.value
    this.setState(fieldAccessor.set(value))
  }

  private handleDescriptionChange = (description: string) => {
    this.setState({ description: description })
  }

  private getUpdateToolItem = () => {
    return {
      id: this.state.id,
      name: this.state.name,
      shortDescription: this.state.shortDescription,
      description: this.state.description,
      homepageLink: this.state.homepageLink,
      documentationLink: this.state.documentationLink,
      visible: this.state.visible,
      searchField: this.state.name.toLowerCase(),
      free: this.state.free,
      openSource: this.state.openSource,
      category: this.state.category,
      __typename: "Tool"
    }
  }

  private getCreateToolItem = () => {
    return {
      name: this.state.name,
      shortDescription: this.state.shortDescription,
      description: this.state.description,
      homepageLink: this.state.homepageLink,
      documentationLink: this.state.documentationLink,
      visible: this.state.visible,
      searchField: this.state.name.toLowerCase(),
      free: this.state.free,
      openSource: this.state.openSource,
      category: this.state.category,
      __typename: "Tool"
    }
  }

  private getFormErrors = () => {
    return catOptions([
      validateNonEmpty(this.state.name, "Name"),
      validateNonEmpty(this.state.shortDescription, "Short description"),
      validateNonEmpty(this.state.description, "Description"),
      validateNonEmpty(this.state.homepageLink, "Homepage link")
    ])
  }

  private showSuccessMessage = (data: any, isUpdate: boolean) => {
    if (data) {
      toast.success(
        `${data.name} has been ${
          isUpdate ? "updated" : "sent to review, thanks for adding it"
        }!`
      )
    }
  }

  private showErrorMessage = () => {
    toast.error("Something went wrong, please try again")
  }
}

export default AddToolPage
