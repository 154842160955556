import * as React from "react"
import { Col, Container, Row } from "reactstrap"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ToolFilter from "../molecules/ToolFilter"
import FilterToolList from "../organisms/FilterToolList"
import ReactTrackedComponent from "./ReactTrackedComponent"

class SearchPage extends ReactTrackedComponent {
  public render = () => {
    return (
      <Container className="search-page-container">
        {process.env.REACT_APP_STAGE != "prod" && <NoIndexHelmet />}
        <Row>
          <Col md={{ size: "3" }}>
            <h4 className="search-bar-title">Filter</h4>
            <ToolFilter />
          </Col>
          <Col md={{ size: "9" }}>
            <h4 className="catalog-title">Catalog</h4>
            <FilterToolList visible={true} />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SearchPage
