import * as React from "react"
import { connect } from "react-redux"
import { setSearchStringFilter } from "../../actions/catalogFilter"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"
import SearchBar from "./SearchBar"

interface Props {
  searchString: string
  selectSearchString: Function
}

class CatalogSearchBar extends ReactTrackedComponent<Props> {
  public render = () => (
    <SearchBar
      searchString={this.props.searchString}
      selectSearchString={this.props.selectSearchString}
    />
  )
}

const mapStateToProps = (state: any) => ({
  searchString: state.catalogFilter.searchString
})

const mapDispatchToProps = (dispatch: any) => ({
  selectSearchString: (filter: string) =>
    dispatch(setSearchStringFilter(filter))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogSearchBar)
