import React from "react"
import { Category } from "../../@types/Category"
import Routes from "../../@types/Routes"
import { setCategoryAndCleanOtherFilters } from "../../actions/catalogFilter"
import store from "../../stores/store"
import ActionLink from "./ActionLink"

interface Props {
  imagePath: string
  enabled: boolean
  category: Category
}

const CategoryGridItemLabel = (props: Props) => {
  let label = (
    <label
      className={props.enabled ? "" : "disabled"}
      style={props.enabled ? { cursor: "pointer" } : {}}
    >
      <img className="category-grid-item-img" src={props.imagePath} />
      {props.category.name}
    </label>
  )

  return props.enabled ? addLink(label, props.category.id) : label
}

const addLink = (children: JSX.Element, categoryKey: string) => {
  return (
    <ActionLink
      to={Routes.CATALOG}
      onClickFunctions={[
        () => store.dispatch(setCategoryAndCleanOtherFilters(categoryKey))
      ]}
    >
      {children}
    </ActionLink>
  )
}

export default CategoryGridItemLabel
