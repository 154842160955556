import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"
import authentication from "./authentication"
import catalogFilter from "./catalogFilter"
import postFilter from "./postFilter"

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    authentication,
    catalogFilter,
    postFilter
  })
