import React from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"
import Post from "../../@types/Post"
import { PostFilter } from "../../@types/PostFilter"
import PostFilterState from "../../@types/PostFilterState"
import { getPosts } from "../../services/PostService"
import InfoMessage from "../atoms/InfoMessage"
import PostList from "../molecules/PostList"

interface Props {
  postFilterState: PostFilterState
  username?: string
  visible?: boolean
}

interface State {
  posts: Post[]
  isLoading: boolean
}

class FilterPostList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      posts: [],
      isLoading: true
    }
  }

  componentDidMount() {
    this.getPosts()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.postFilterState != this.props.postFilterState) {
      this.setState({ isLoading: true })
      this.getPosts()
    }
  }

  render = () => (
    <div className="post-grid">
      {this.state.isLoading ? (
        <Spinner className="spinner" type="grow" />
      ) : this.state.posts && this.state.posts.length > 0 ? (
        <PostList posts={this.state.posts} />
      ) : (
        <InfoMessage>No posts found</InfoMessage>
      )}
    </div>
  )

  private getFilters = (): PostFilter => ({
    text: this.props.postFilterState.searchString,
    visible: typeof this.props.visible == "boolean" ? this.props.visible : true,
    userName: this.props.username
  })

  private getPosts = async () => {
    try {
      const posts = await getPosts(this.getFilters())
      this.setState({ posts: posts.data, isLoading: false })
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false })
    }
  }
}

const mapStateToProps = (state: any) => ({
  postFilterState: state.postFilter
})

export default connect(mapStateToProps)(FilterPostList)
