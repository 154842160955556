import { fromNullable } from "fp-ts/lib/Option"
import * as React from "react"
import ReactGA from "react-ga"
import { Link } from "react-router-dom"
import StarRatingComponent from "react-star-rating-component"
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap"
import Routes from "../../@types/Routes"
import Tool from "../../@types/Tool"

interface Props {
  tool: Tool
}

const ToolListItem = (props: Props): JSX.Element => {
  return (
    <Card className="tool-card">
      <CardBody>
        <CardTitle className="h4 tool-card-title">
          <div className="dv-title-free">
            <label>
              <Link
                to={Routes.SHOW_TOOL + props.tool.id}
                className="card-tool-title-link"
              >
                {props.tool.name}
              </Link>
              {props.tool.free ? (
                <span className="tool-free-field">Free</span>
              ) : (
                ""
              )}
            </label>
          </div>
          <div className="non-editable">
            <StarRatingComponent
              name="rate"
              starColor="#ffb400"
              emptyStarColor="#D1D1D1"
              starCount={5}
              value={fromNullable(props.tool.rating).getOrElse(0)}
            />
          </div>
        </CardTitle>
        <div className="tool-card-body">
          <Row noGutters>
            <Col
              xl={{ size: 4, order: 1 }}
              lg={{ size: 3, order: 1 }}
              md={{ size: 4, order: 1 }}
              xs={{ order: 2 }}
            >
              <div className="tool-props-container">
                <h6 className="tool-props-title">Category</h6>
                <p className="tool-props-value">{props.tool.categoryName}</p>
              </div>
              <div className="tool-props-container">
                <h6 className="tool-props-title">Product</h6>
                <p className="tool-props-value">
                  {props.tool.openSource ? "Open source" : "Propietary"}
                </p>
              </div>
            </Col>
            <Col
              xl={{ size: 8, order: 2 }}
              lg={{ size: 9, order: 2 }}
              md={{ size: 8, order: 2 }}
              xs={{ order: 1 }}
            >
              <div className="tool-props-container">
                <p className="tool-props-value">
                  {props.tool.shortDescription}
                </p>
              </div>
              {props.tool.homepageLink && (
                <ReactGA.OutboundLink
                  eventLabel={"Homepage Link clicked from Tool List"}
                  to={props.tool.homepageLink}
                  target="_blank"
                  className="btn tool-link"
                >
                  <img className="tool-link-img" src="/images/link_white.png" />
                  Homepage
                </ReactGA.OutboundLink>
              )}
              {props.tool.documentationLink && (
                <ReactGA.OutboundLink
                  eventLabel={"Documentation Link clicked from Tool List"}
                  to={props.tool.documentationLink}
                  target="_blank"
                  className="btn tool-link"
                >
                  <img
                    className="tool-link-img"
                    src="/images/folder_white.png"
                  />
                  Documentation
                </ReactGA.OutboundLink>
              )}
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

export default ToolListItem
