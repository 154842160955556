import { push } from "connected-react-router"
import { fromNullable } from "fp-ts/lib/Option"
import React from "react"
import { Button } from "reactstrap"
import Routes from "../../@types/Routes"
import Tool from "../../@types/Tool"
import store from "../../stores/store"
import { getAuthState, isAdmin } from "../../util/AuthenticationUtils"
import ModalDelete from "./ModalDelete"

interface Props {
  tool: Tool
  reloadAfterDelete?: Boolean
}

class ToolActionButtons extends React.Component<Props> {
  render = () => {
    const authState = getAuthState()

    return (
      <div>
        {isAdmin(authState) && (
          <Button
            color="secondary"
            onClick={() =>
              store.dispatch(
                push({
                  pathname: Routes.EDIT_TOOL + this.props.tool.id
                })
              )
            }
            className="add-form-button mr-2"
          >
            Edit
          </Button>
        )}
        {fromNullable(authState)
          .map(
            authState =>
              isAdmin(authState) && (
                <ModalDelete
                  element={this.props.tool}
                  type="tool"
                  reloadAfterDelete={this.props.reloadAfterDelete}
                />
              )
          )
          .toNullable()}
      </div>
    )
  }
}

export default ToolActionButtons
