import * as React from "react"
import { Link } from "react-router-dom"
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap"
import Post from "../../@types/Post"
import Routes from "../../@types/Routes"
import store from "../../stores/store"
import { cleanPostFilter } from "../../actions/postFilter"
import ActionLink from "./ActionLink"
import PostTag from "./PostTag"
import { formatDate } from "../../util/Function"

interface Props {
  post: Post
}

const PostListItem = (props: Props): JSX.Element => {
  return (
    <Card className="post-card">
      <CardBody>
        <CardTitle className="h4 post-card-title">
          <div className="dv-title">
            <label>
              <Link
                to={Routes.SHOW_POST + props.post.id}
                className="card-post-title-link"
              >
                {props.post.title}
              </Link>
            </label>
          </div>
        </CardTitle>
        <div className="post-card-body">
          <Row noGutters>
            <Col xs={6} md={"auto"}>
              <div className="post-props-container">
                <h6 className="post-props-title">Created</h6>
                <p className="post-props-value">
                  {formatDate(props.post.createdAt)}
                </p>
              </div>
            </Col>
            <Col xs={6} md={"auto"}>
              <div className="post-props-container">
                <h6 className="post-props-title">Author</h6>
                <p className="post-props-value">
                  <ActionLink
                    to={`${Routes.POSTS}/${props.post.userName}`}
                    onClickFunctions={[() => store.dispatch(cleanPostFilter())]}
                  >
                    {props.post.userName}
                  </ActionLink>
                </p>
              </div>
            </Col>
            <Col md={"auto"}>
              <div className="post-props-container">
                <h6 className="post-props-title">Tags</h6>
                <p className="post-props-value">
                  {props.post.tags.map(tag => (
                    <PostTag tag={tag} key={tag.id} />
                  ))}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

export default PostListItem
