import * as React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

interface Props {
  onClickFunctions: Function[]
  className?: string
  to: string
}

class ActionLink extends React.Component<Props> {
  render = () => {
    return (
      <Link
        className={this.props.className}
        to={this.props.to}
        onClick={this.functionsToExecute}
      >
        {this.props.children}
      </Link>
    )
  }

  private functionsToExecute = () => {
    this.props.onClickFunctions.map(func => func())
  }
}

export default connect()(ActionLink)
