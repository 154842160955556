import { fromNullable } from "fp-ts/lib/Option"
import * as React from "react"
import { Col, Row } from "reactstrap"
import Tool from "../../@types/Tool"
import ErrorMessage from "../atoms/ErrorMessage"
import ToolListItem from "../atoms/ToolListItem"

interface Props {
  tools: Tool[]
}

class ToolList extends React.Component<Props> {
  public render = () => (
    <Row className={"equal-height-cards"}>
      {fromNullable(this.props.tools)
        .map(tools => tools.map(this.renderTool))
        .getOrElse([renderError])}
    </Row>
  )

  private renderTool = (tool: Tool) => {
    return (
      <Col xl="6" key={tool.id}>
        <ToolListItem tool={tool} />
      </Col>
    )
  }
}

const renderError = <ErrorMessage>Could not load catalog</ErrorMessage>

export default ToolList
