import * as React from "react"
import ReactGA from "react-ga"
import { Link } from "react-router-dom"
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap"
import Routes from "../../@types/Routes"
import Tool from "../../@types/Tool"
import ToolActionButtons from "./ToolActionButtons"

interface Props {
  tool: Tool
}

const ReviewToolItem = (props: Props): JSX.Element => (
  <Card className="tool-card">
    <CardBody>
      <CardTitle className="h4 tool-card-title">
        <div className="dv-title-free">
          <label>
            <Link
              to={Routes.EDIT_TOOL + props.tool.id}
              className="card-tool-title-link"
            >
              {props.tool.name}
            </Link>
            {props.tool.free ? (
              <span className="tool-free-field">Free</span>
            ) : (
              ""
            )}
            <button className="visibility-button" type="button">
              <img
                className="visibility-img"
                src={
                  props.tool.visible
                    ? "/images/Icon-Visible.svg"
                    : "/images/Icon-Invisible.svg"
                }
              />
            </button>
          </label>
        </div>
      </CardTitle>
      <div className="tool-card-body">
        <Row noGutters>
          <Col
            xl={{ size: 3, order: 1 }}
            lg={{ size: 2, order: 1 }}
            xs={{ order: 2 }}
          >
            <div className="tool-props-container">
              <h6 className="tool-props-title">Category</h6>
              <p className="tool-props-value">{props.tool.categoryName}</p>
            </div>
            <div className="tool-props-container">
              <h6 className="tool-props-title">Product</h6>
              <p className="tool-props-value">
                {props.tool.openSource ? "Open source" : "Propietary"}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {props.tool.homepageLink && (
                <ReactGA.OutboundLink
                  eventLabel={"Homepage Link clicked from Tool List"}
                  to={props.tool.homepageLink}
                  target="_blank"
                  className="review tool-link"
                >
                  <img
                    className="tool-link-img"
                    src="/images/link_purple.svg"
                  />
                  Homepage
                </ReactGA.OutboundLink>
              )}
              {props.tool.documentationLink && (
                <ReactGA.OutboundLink
                  eventLabel={"Documentation Link clicked from Tool List"}
                  to={props.tool.documentationLink}
                  target="_blank"
                  className="review tool-link"
                >
                  <img
                    className="tool-link-img"
                    src="/images/folder_purple.svg"
                  />
                  Documentation
                </ReactGA.OutboundLink>
              )}
            </div>
          </Col>
          <Col
            xl={{ size: 9, offset: 0, order: 2 }}
            lg={{ size: 8, offset: 1, order: 2 }}
            xs={{ order: 1 }}
          >
            <div className="tool-props-container mr-4">
              <p className="tool-props-value short-description">
                {props.tool.shortDescription}
              </p>
              <p className="tool-props-value">{props.tool.description}</p>
            </div>
          </Col>
        </Row>
        <div className="review-buttons-container mr-4">
          <ToolActionButtons tool={props.tool} reloadAfterDelete={true} />
        </div>
      </div>
    </CardBody>
  </Card>
)

export default ReviewToolItem
