import * as React from "react"
import { connect } from "react-redux"
import { FormGroup, Input, Label } from "reactstrap"
import {
  setFreePriceFilter,
  setPaidPriceFilter
} from "../../actions/catalogFilter"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"

const FREE = "free"
const PAID = "paid"

interface Props {
  free: boolean
  paid: boolean
  selectFree: Function
  selectPaid: Function
}

class PriceSearchBox extends ReactTrackedComponent<Props> {
  public render = () => (
    <div>
      <Label for="price" className="pt-3 pb-1">
        Price
      </Label>
      <FormGroup className="checkbutton-form-group mb-0">
        <Input
          label="Free"
          name="price"
          value={FREE}
          type="checkbox"
          checked={this.props.free}
          onChange={this.onChangePriceHandler}
          className="filter-input-checkbox"
        />
        <Label for="price" className="filter-input-label">
          Free
        </Label>
      </FormGroup>
      <FormGroup className="checkbutton-form-group mb-0">
        <Input
          name="price"
          value={PAID}
          type="checkbox"
          checked={this.props.paid}
          onChange={this.onChangePriceHandler}
          className="filter-input-checkbox"
        />
        <Label for="price" className="filter-input-label">
          Paid
        </Label>
      </FormGroup>
    </div>
  )

  private onChangePriceHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value == FREE) {
      this.props.selectFree(event.target.checked)
    }
    if (event.target.value == PAID) {
      this.props.selectPaid(event.target.checked)
    }
    this.fireEvent("Searcher", "Price search", event.target.value)
  }
}

const mapStateToProps = (state: any) => ({
  free: state.catalogFilter.free,
  paid: state.catalogFilter.paid
})

const mapDispatchToProps = (dispatch: any) => ({
  selectFree: (checked: boolean) => dispatch(setFreePriceFilter(checked)),
  selectPaid: (checked: boolean) => dispatch(setPaidPriceFilter(checked))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceSearchBox)
