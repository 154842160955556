import { Auth } from "aws-amplify"
import { push } from "connected-react-router"
import { Lens } from "monocle-ts"
import * as React from "react"
import { Alert, Button, Col, Container, Form, FormGroup, Row } from "reactstrap"
import Routes from "../../@types/Routes"
import store from "../../stores/store"
import { AuthExceptions } from "../../util/Constants"
import AuthInput from "../atoms/AuthInput"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ReactTrackedComponent from "./ReactTrackedComponent"

interface State {
  username: string
  newPassword: string
  code: string
  alert: {
    visible: boolean
    type: any
    message: string
    color: string
  }
  codeReceived: boolean
}

const InitialState = {
  username: "",
  newPassword: "",
  code: "",
  alert: {
    visible: false,
    type: null,
    message: "",
    color: ""
  },
  codeReceived: false
}

class ForgotPassword extends ReactTrackedComponent<any, State> {
  constructor(props: any) {
    super(props)

    this.state = InitialState

    this.onDimissAlert = this.onDimissAlert.bind(this)
  }

  public render() {
    return (
      <Container className="authentication-container">
        <NoIndexHelmet />
        {this.state.codeReceived
          ? this.renderResetPassword()
          : this.renderForgotPassword()}
      </Container>
    )
  }

  private onRecoverPasswordClicked = (event: any) => {
    event.preventDefault()
    if (this.validateForgotPasswordForm()) {
      Auth.forgotPassword(this.state.username)
        .then(result => {
          this.handleSuccess(result)
        })
        .catch(err => {
          this.handleError(err)
        })
    }
  }

  private onResetPasswordClicked = (event: any) => {
    event.preventDefault()
    if (this.validateResetPasswordForm()) {
      Auth.forgotPasswordSubmit(
        this.state.username,
        this.state.code,
        this.state.newPassword
      )
        .then(result => {
          this.handleSuccess(result)
        })
        .catch(err => {
          this.handleError(err)
        })
    }
  }

  private onDimissAlert = () => {
    this.setState({
      alert: { visible: false, type: null, message: "", color: "" }
    })
  }

  private renderResetPassword() {
    return (
      <Row>
        <Col
          className="my-5 authentication-content"
          lg={{ size: 4, offset: 4 }}
        >
          <h3 className="authentication-title">
            <span className="authentication-title-action">Reset password</span>
          </h3>
          <Form
            className="authentication-form"
            onSubmit={this.onResetPasswordClicked}
          >
            <FormGroup>
              <AuthInput
                disabled={true}
                placeholder="Username"
                name="username"
                type="text"
                value={this.state.username}
                onChangeCallback={this.handleChange}
              />
              <AuthInput
                autoFocus={true}
                placeholder="Verification code"
                name="code"
                type="text"
                value={this.state.code}
                onChangeCallback={this.handleChange}
              />
              <AuthInput
                placeholder="New password"
                name="newPassword"
                type="password"
                value={this.state.newPassword}
                onChangeCallback={this.handleChange}
              />
            </FormGroup>
            <Alert
              color={this.state.alert.color}
              isOpen={this.state.alert.visible}
              toggle={this.onDimissAlert}
            >
              {this.state.alert.message}
            </Alert>
            <Button block className="authentication-button" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    )
  }

  private renderForgotPassword() {
    return (
      <Row>
        <Col
          className="my-5 authentication-content"
          lg={{ size: 4, offset: 4 }}
        >
          <h3 className="authentication-title">
            <span className="authentication-title-action">
              Forgot your password?
            </span>
          </h3>
          <Form
            className="authentication-form"
            onSubmit={this.onRecoverPasswordClicked}
            autoComplete="never"
          >
            <FormGroup>
              <AuthInput
                autoFocus={true}
                placeholder="Username"
                name="username"
                type="text"
                value={this.state.username}
                onChangeCallback={this.handleChange}
              />
            </FormGroup>
            <Alert
              color={this.state.alert.color}
              isOpen={this.state.alert.visible}
              toggle={this.onDimissAlert}
            >
              {this.state.alert.message}
            </Alert>
            <Button block className="authentication-button" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    )
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setField(event.target.name as keyof State, event.target.value)

  private handleError = (error: any) => {
    console.log("Error", error)

    let errorMessage = ""

    switch (error.code) {
      case AuthExceptions.INVALID_PARAMETER_EXCEPTION:
        if (error.message.includes("username")) {
          errorMessage = "Please check your input"
          break
        }
      case AuthExceptions.NOT_AUTHORIZED_EXCEPTION:
      case AuthExceptions.LIMIT_EXCEEDED_EXCEPTION:
      case AuthExceptions.CODE_EXPIRED_EXCEPTION:
      case AuthExceptions.CODE_MISMATCH_EXCEPTION:
      case AuthExceptions.INVALID_PASSWORD_EXCEPTION:
        errorMessage = error.message
        break
      // We shouldn't let the user know that a user does not exist
      case AuthExceptions.USER_NOT_FOUND_EXCEPTION:
      default:
        errorMessage = "Something went wrong"
    }

    this.setState({
      alert: {
        visible: true,
        type: error.code,
        message: errorMessage,
        color: "danger"
      }
    })
  }

  private handleSuccess = (result: any) => {
    console.log("Success", result)

    if (result && result.CodeDeliveryDetails) {
      this.setState({
        codeReceived: true,
        alert: {
          visible: true,
          type: null,
          message: `Verification code sent to ${
            result.CodeDeliveryDetails.Destination
          }`,
          color: "info"
        }
      })
    } else {
      this.setState({
        username: "",
        newPassword: "",
        code: ""
      })
      store.dispatch(
        push({
          pathname: Routes.LOGIN,
          state: {
            loginMessage: "Password successfully reset"
          }
        })
      )
    }
  }

  private setField = (field: keyof State, value: any): void => {
    const fieldAccessor = Lens.fromProp<State>()(field as keyof State)
    this.setState(fieldAccessor.set(value))
  }

  private setAlertState = (message: string, color: string): void => {
    this.setState({
      alert: {
        visible: true,
        type: null,
        message: message,
        color: color
      }
    })
  }

  private validateForgotPasswordForm = (): boolean => {
    if (this.state.username.trim().length === 0) {
      this.setAlertState("Username cannot be empty", "danger")
      return false
    }
    return true
  }

  private validateResetPasswordForm = (): boolean => {
    if (this.state.code.trim().length === 0) {
      this.setAlertState("Code cannot be empty", "danger")
      return false
    }
    if (this.state.newPassword.trim().length === 0) {
      this.setAlertState("Password cannot be empty", "danger")
      return false
    }
    return true
  }
}

export default ForgotPassword
