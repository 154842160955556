import * as React from "react"
import StarRatingComponent from "react-star-rating-component"
import ToolRating from "../../@types/ToolRating"
import { getToolRating, updateToolRating } from "../../services/ToolService"
import { getAuthState } from "../../util/AuthenticationUtils"

interface Props {
  toolId: string
  refreshToolDetail: Function
}

interface State {
  toolRating: ToolRating
}

class UserToolRating extends React.Component<Props, State> {
  private authState: any

  constructor(props: Props) {
    super(props)
    this.authState = getAuthState()

    this.state = {
      toolRating: {
        toolId: this.props.toolId,
        rating: 0
      }
    }
  }

  componentDidMount() {
    this.getUserToolRating()
  }

  public render = () => (
    <div className="tool-view-rating-container">
      {this.authState && this.authState.isAuthenticated ? (
        <div className="user-rating-container">
          <label className="user-rating-title">Your rating: </label>
          <StarRatingComponent
            name="rate"
            starCount={5}
            value={this.state.toolRating.rating}
            starColor="#8166BC"
            emptyStarColor="#D1D1D1"
            onStarClick={this.onYourRatingChange.bind(this)}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )

  private onYourRatingChange = (rating: number) => {
    const toolRating: ToolRating = {
      toolId: this.props.toolId,
      rating
    }
    this.setState({
      toolRating: toolRating
    })

    this.updateUserToolRating(toolRating)
  }

  private updateUserToolRating = (toolRating: ToolRating) => {
    updateToolRating(toolRating)
      .then(updateToolRatingResponse => {
        this.props.refreshToolDetail()
      })
      .catch(error => {
        console.error(error)
      })
  }

  private getUserToolRating = () => {
    if (this.authState && this.authState.isAuthenticated) {
      getToolRating(this.props.toolId)
        .then(response => {
          this.setState({
            toolRating: response.data
          })
        })
        .catch(_ => {
          this.setState({
            toolRating: {
              toolId: this.props.toolId,
              rating: 0
            }
          })
        })
    }
  }
}

export default UserToolRating
