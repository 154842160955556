import * as React from "react"
import { Container, Col, Row } from "reactstrap"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import CategoryGrid from "../organisms/CategoryGrid"
import ReactTrackedComponent from "./ReactTrackedComponent"

interface CategoriesState {
  activeCategoryKeys: string[]
}

const InitialState: CategoriesState = {
  activeCategoryKeys: []
}

class CategoriesPage extends ReactTrackedComponent<any, CategoriesState> {
  constructor(props: any) {
    super(props)

    this.state = InitialState
  }

  public render = () => (
    <Container className="category-container">
      {process.env.REACT_APP_STAGE != "prod" && <NoIndexHelmet />}
      <Row>
        <Col
          md={{ size: "8", offset: "2" }}
          xs={{ size: "12" }}
          lg={{ size: "8", offset: "2" }}
        >
          <h4 className="category-container-title">Categories</h4>
          <CategoryGrid />
        </Col>
      </Row>
    </Container>
  )
}

export default CategoriesPage
