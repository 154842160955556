import { Option, none, fromNullable } from "fp-ts/lib/Option"
import React from "react"
import { Spinner } from "reactstrap"
import Tool from "../../@types/Tool"
import ErrorMessage from "../atoms/ErrorMessage"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import AddToolPage from "./AddToolPage"
import ReactTrackedComponent from "./ReactTrackedComponent"
import { getTool } from "../../services/ToolService"

interface Props {
  match: any
}

interface State {
  tool: Option<Tool>
  isLoading: boolean
}

class EditToolPage extends ReactTrackedComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tool: none,
      isLoading: true
    }
  }

  componentDidMount() {
    this.getToolDetail()
  }

  render = () => {
    return (
      <div>
        <NoIndexHelmet />
        {this.state.isLoading ? (
          <Spinner className="spinner" type="grow" />
        ) : this.state.tool.isSome() ? (
          <AddToolPage tool={this.state.tool.value} />
        ) : (
          <ErrorMessage>Error trying to edit the tool</ErrorMessage>
        )}
      </div>
    )
  }

  private getToolDetail = () => {
    const toolId = this.props.match.params.id
    getTool(toolId)
      .then(toolResponse => {
        this.setState({
          tool: fromNullable(toolResponse.data),
          isLoading: false
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false })
      })
  }
}

export default EditToolPage
