import { CognitoUser } from "amazon-cognito-identity-js"
import UserGroups from "../@types/UserGroups"
import AuthState from "../@types/AuthState"
import { fromNullable } from "fp-ts/lib/Option"
import { Auth } from "aws-amplify"
import store from "../stores/store"

export const getAuthStateFromCognitoUser = (user: CognitoUser): AuthState => {
  const cognitoUserSession = user.getSignInUserSession()
  if (cognitoUserSession) {
    const payload = cognitoUserSession.getAccessToken().decodePayload()
    const group =
      payload["cognito:groups"] &&
      payload["cognito:groups"].indexOf(UserGroups.ADMIN) > -1
        ? UserGroups.ADMIN
        : UserGroups.CONTRIBUTOR
    return {
      isAuthenticated: true,
      username: user.getUsername(),
      userGroup: group,
      userId: payload.sub
    }
  } else {
    return {
      isAuthenticated: false
    }
  }
}

export const isAdmin = (authState?: AuthState) =>
  getUserGroup(authState) === UserGroups.ADMIN

export const isContributor = (authState?: AuthState) =>
  getUserGroup(authState) === UserGroups.CONTRIBUTOR

export const getJwtToken = async () =>
  (await Auth.currentSession()).getIdToken().getJwtToken()

export const getAuthState = () => {
  return store.getState()["authentication"]["auth"]
}

export const validRole = (allowedRoles: [UserGroups]) => {
  if (!allowedRoles) {
    return false
  }

  const userGroup = getUserGroup(getAuthState())
  const validRoles = allowedRoles.filter(role => userGroup === role)
  return validRoles.length > 0
}

export const setAuthState = (state: AuthState | null) => {
  store.dispatch({
    type: "UPDATE_AUTHENTICATION_STATUS",
    auth: state
  })
}

const getUserGroup = (authState?: AuthState) =>
  fromNullable(authState)
    .chain(authState => fromNullable(authState.userGroup))
    .map(userGroup => userGroup.toString())
    .getOrElse("")
