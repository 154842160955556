import * as React from "react"
import { Col, Row, Input, Card, CardBody, CardTitle, Button } from "reactstrap"
import Comment from "../../@types/Comment"
import { getAuthState, isAdmin } from "../../util/AuthenticationUtils"
import { formatDate } from "../../util/Function"
import { Lens } from "monocle-ts"
import { editComment } from "../../services/CommentService"
import { toast } from "react-toastify"
import ModalDelete from "./ModalDelete"

interface Props {
  comment: Comment
  onDelete: Function
  onUpdate: Function
}

interface State {
  isEditing: boolean
  editingComment?: string
}

class CommentListItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isEditing: false
    }
  }

  public render = () => {
    const authState = getAuthState()
    const createdAt = new Date(this.props.comment.createdAt)

    return (
      <Card className="post-card">
        <CardBody>
          <CardTitle className="h6 post-card-title">
            <div className="dv-title comment-dv-title">
              <label className="comment-item-title">
                {this.props.comment.username}
              </label>
              <label className="comment-item-title-date">
                {`- ${formatDate(
                  this.props.comment.createdAt
                )} - ${createdAt.toLocaleTimeString()}`}
              </label>
              <div className="action-button-div">
                {authState &&
                  authState.isAuthenticated &&
                  authState.username == this.props.comment.username &&
                  !this.state.isEditing && (
                    <Button
                      className="btn-small btn-comment-edit"
                      onClick={this.handleOnEditClick}
                    >
                      Edit
                    </Button>
                  )}
                {((authState &&
                  authState.isAuthenticated &&
                  authState.username == this.props.comment.username) ||
                  isAdmin(authState)) &&
                  !this.state.isEditing && (
                    <ModalDelete
                      element={this.props.comment}
                      type={"comment"}
                      className="btn-small btn-comment-delete"
                      callbackFunction={this.handleOnDeleteClick}
                    />
                  )}
              </div>
            </div>
          </CardTitle>
          <div className="card-comment-body">
            <Row noGutters>
              <Col md={"auto"} className="edit-comment-container">
                {this.state.isEditing && (
                  <div>
                    <Input
                      name="editingComment"
                      type="textarea"
                      value={this.state.editingComment}
                      onChange={this.handleOnTextChange}
                      className="mb-2"
                    />
                    <Button
                      type="button"
                      className="add-form-button mr-2"
                      onClick={this.handleOnSaveCommentClick}
                    >
                      Save
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      className="cancel-button"
                      onClick={this.handleOnCancelEditingCommentClick}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {!this.state.isEditing && (
                  <div className="post-props-container">
                    {this.props.comment.message}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    )
  }

  private handleOnDeleteClick = () => {
    this.props.onDelete(this.props.comment.id)
  }

  private handleOnEditClick = () => {
    this.setState({
      editingComment: this.props.comment.message,
      isEditing: true
    })
  }

  private handleOnTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const fieldAccessor = Lens.fromProp<State>()(event.target
      .name as keyof State)
    this.setState(fieldAccessor.set(value))
  }

  private handleOnSaveCommentClick = () => {
    if (this.state.editingComment != this.props.comment.message) {
      this.editComment()
    }
  }

  private handleOnCancelEditingCommentClick = () => {
    this.setState({ isEditing: false })
  }

  private editComment = async () => {
    try {
      const comment = await editComment(
        this.props.comment.id,
        this.generateCommentItem()
      )
      this.props.onUpdate(this.props.comment.id, comment.data.message)
      this.setState({ isEditing: false })
      this.showSuccessMessage()
    } catch (error) {
      console.error(error)
      this.showErrorMessage()
    }
  }

  private generateCommentItem = () => {
    return {
      message: this.state.editingComment
    }
  }

  private showSuccessMessage = () => {
    toast.success("The comment has been sent!")
  }

  private showErrorMessage = () => {
    toast.error("Something went wrong, please try again")
  }
}

export default CommentListItem
