export const CLEAN_POST_FILTER = "cleanPostFilter"
export const SET_SEARCH_STRING_POST_FILTER = "setSearchStringPostFilter"

export function cleanPostFilter() {
  return {
    type: CLEAN_POST_FILTER
  }
}

export function setSearchStringPostFilter(searchString: string) {
  return {
    type: SET_SEARCH_STRING_POST_FILTER,
    searchString
  }
}
