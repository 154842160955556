import React from "react"
import { WithContext as ReactTags } from "react-tag-input"
import { Tag } from "../../@types/Tag"

const KeyCodes = {
  comma: 188,
  enter: 13
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

interface Props {
  tags: Tag[]
  suggestions: Tag[]
  handleDelete: (i: number) => void
  handleAddition: (tag: Tag) => void
}

const TagInput = (props: Props) => {
  return (
    <div>
      <ReactTags
        tags={props.tags}
        suggestions={props.suggestions}
        handleDelete={props.handleDelete}
        handleAddition={props.handleAddition}
        delimiters={delimiters}
        allowDragDrop={false}
        autofocus={false}
      />
    </div>
  )
}

export default TagInput
