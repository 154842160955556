import PostFilterState from "../@types/PostFilterState"
import {
  CLEAN_POST_FILTER,
  SET_SEARCH_STRING_POST_FILTER
} from "../actions/postFilter"

const initialState: PostFilterState = {
  searchString: null
}

const postFilter = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAN_POST_FILTER:
      return {
        ...initialState
      }
    case SET_SEARCH_STRING_POST_FILTER:
      return {
        ...state,
        searchString: action.searchString
      }
    default:
      return state
  }
}

export default postFilter
