import { CognitoUser } from "amazon-cognito-identity-js"
import { Auth } from "aws-amplify"
import { push } from "connected-react-router"
import { Lens } from "monocle-ts"
import * as React from "react"
import { Link } from "react-router-dom"
import { Alert, Button, Col, Container, Form, FormGroup, Row } from "reactstrap"
import AuthState from "../../@types/AuthState"
import Routes from "../../@types/Routes"
import store from "../../stores/store"
import {
  getAuthState,
  getAuthStateFromCognitoUser,
  setAuthState
} from "../../util/AuthenticationUtils"
import AuthInput from "../atoms/AuthInput"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ReactTrackedComponent from "./ReactTrackedComponent"

const USER_NOT_CONFIRMED_EXCEPTION = "UserNotConfirmedException"
const PASSWORD_RESET_REQUIRED_EXCEPTION = "PasswordResetRequiredException"

interface State {
  username: string
  password: string
  error: String
  showChangePassword: boolean
  cognitoUser?: CognitoUser
  newPassword: string
  repeatPassword: string
  redirectToSignUp: boolean
  loginMessage: string
}

const InitialState = {
  username: "",
  password: "",
  error: "",
  showChangePassword: false,
  newPassword: "",
  repeatPassword: "",
  redirectToSignUp: false,
  loginMessage: ""
}

class Login extends ReactTrackedComponent<any, State> {
  constructor(props: any) {
    super(props)

    if (props.location.state && props.location.state.loginMessage) {
      InitialState.loginMessage = props.location.state.loginMessage
    }

    this.state = InitialState
  }

  public render = () => {
    const authState: AuthState = getAuthState()

    if (authState && authState.isAuthenticated) {
      store.dispatch(push(Routes.HOME))
    }

    if (this.state.redirectToSignUp) {
      store.dispatch(
        push({
          pathname: Routes.SIGNUP,
          state: { toVerificationCode: true, username: this.state.username }
        })
      )
    }

    return (
      <Container className="authentication-container">
        <NoIndexHelmet />
        <Row>
          <Col
            className="my-5 authentication-content"
            lg={{ size: 4, offset: 4 }}
          >
            <Alert color="success" isOpen={this.state.loginMessage.length > 0}>
              {this.state.loginMessage}
            </Alert>
            <h3 className="authentication-title">
              <span className="authentication-title-action">Log in </span>
              to your account
            </h3>
            {!this.state.showChangePassword
              ? this.renderLogin()
              : this.renderChangePassword()}
          </Col>
        </Row>
      </Container>
    )
  }

  private renderLogin() {
    return (
      <Form className="authentication-form" onSubmit={this.handleLogin}>
        <FormGroup>
          <AuthInput
            autoFocus={true}
            placeholder="Username"
            name="username"
            type="text"
            value={this.state.username}
            onChangeCallback={this.handleChange}
          />
          <AuthInput
            placeholder="Password"
            name="password"
            type="password"
            value={this.state.password}
            onChangeCallback={this.handleChange}
          />
          <div className="authentication-forgot-password-link">
            <Link to={Routes.FORGOT_PASSWORD}>Forgot password?</Link>
          </div>
        </FormGroup>
        <Alert color="danger" isOpen={this.state.error.length > 0}>
          {this.state.error}
        </Alert>
        <Button block className="authentication-button" type="submit">
          Log in
        </Button>
        <div className="authentication-footer">
          Need an account? <Link to={Routes.SIGNUP}>Sign up</Link>
        </div>
      </Form>
    )
  }

  private renderChangePassword() {
    return (
      <Form
        className="authentication-form"
        onSubmit={this.handleChangePassword}
      >
        <FormGroup>
          <AuthInput
            autoFocus={true}
            placeholder="New Password"
            name="newPassword"
            type="password"
            value={this.state.newPassword}
            onChangeCallback={this.handleChange}
          />
          <AuthInput
            placeholder="Repeat Password"
            name="repeatPassword"
            type="password"
            value={this.state.repeatPassword}
            onChangeCallback={this.handleChange}
          />
        </FormGroup>
        <Alert color="danger" isOpen={this.state.error.length > 0}>
          {this.state.error}
        </Alert>
        <Button block className="authentication-button" type="submit">
          Change Password
        </Button>
      </Form>
    )
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setField(event.target.name as keyof State, event.target.value)

  private handleLogin = (event: any) => {
    event.preventDefault()

    if (this.validLoginDetails()) {
      this.login()
    } else {
      this.setState({
        username: this.state.username,
        password: this.state.password,
        error: "Username and Password cannot be empty"
      })
    }
  }

  private login = () => {
    Auth.signIn(this.state.username, this.state.password)
      .then(cognitoUser => {
        if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          // The user must change the password
          this.setState({
            username: this.state.username,
            password: "",
            error: "",
            showChangePassword: true,
            cognitoUser: cognitoUser,
            newPassword: "",
            repeatPassword: "",
            redirectToSignUp: false,
            loginMessage: ""
          })
        } else {
          // Regular login
          const authState = getAuthStateFromCognitoUser(cognitoUser)
          setAuthState(authState)
          store.dispatch(push(Routes.HOME))
          this.fireEvent("Authentication", "Logged In")
        }
      })
      .catch(err => {
        this.handleLoginError(err)
      })
  }

  private handleLoginError = (error: any) => {
    switch (error.code) {
      case USER_NOT_CONFIRMED_EXCEPTION:
        this.setState({ redirectToSignUp: true })
        break
      // password reset needs to be handled in another story
      case PASSWORD_RESET_REQUIRED_EXCEPTION:
      default:
        console.error(error)
        this.setField("error", error.message)
    }
  }

  private handleChangePassword = (event: any) => {
    event.preventDefault()
    if (this.validatePasswords()) {
      Auth.completeNewPassword(
        this.state.cognitoUser,
        this.state.newPassword,
        null
      )
        .then(_ => {
          this.setState({
            username: this.state.username,
            password: "",
            error: "",
            showChangePassword: false,
            newPassword: "",
            repeatPassword: "",
            redirectToSignUp: false,
            loginMessage: "Password successfully changed"
          })
        })
        .catch(err => {
          console.error(err)
          this.setField("error", err.message)
        })
    }
    this.fireEvent("Authentication", "Password Changed")
  }

  private setField = (field: keyof State, value: any): void => {
    const fieldAccessor = Lens.fromProp<State>()(field as keyof State)
    this.setState(fieldAccessor.set(value))
  }

  private validatePasswords = (): boolean => {
    if (
      this.state.newPassword.trim().length === 0 ||
      this.state.repeatPassword.trim().length === 0
    ) {
      this.setField("error", "The passwords cannot be empty")
      return false
    }
    if (this.state.newPassword !== this.state.repeatPassword) {
      this.setField("error", "The passwords are different")
      return false
    }
    return true
  }

  private validLoginDetails = (): boolean => {
    if (
      this.state.username.trim().length === 0 ||
      this.state.password.trim().length === 0
    ) {
      return false
    }
    return true
  }
}

export default Login
