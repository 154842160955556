import CatalogFilterState from "../@types/CatalogFilterState"
import {
  CLEAN_CATALOG_FILTER,
  SET_CATEGORY_FILTER,
  SET_FREE_PRICE_FILTER,
  SET_OPEN_SOURCE_FILTER,
  SET_PAID_PRICE_FILTER,
  SET_PROPIETARY_FILTER,
  SET_SEARCH_STRING_AND_CLEAN_OTHER_FILTERS,
  SET_SEARCH_STRING_FILTER,
  SET_CATEGORY_AND_CLEAN_OTHER_FILTERS
} from "../actions/catalogFilter"

const initialState: CatalogFilterState = {
  searchString: null,
  category: "",
  free: false,
  paid: false,
  openSource: false,
  propietary: false
}

const catalogFilter = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAN_CATALOG_FILTER:
      return {
        ...initialState
      }
    case SET_SEARCH_STRING_AND_CLEAN_OTHER_FILTERS: {
      return {
        ...initialState,
        searchString: action.searchString
      }
    }
    case SET_SEARCH_STRING_FILTER:
      return {
        ...state,
        searchString: action.searchString
      }
    case SET_CATEGORY_AND_CLEAN_OTHER_FILTERS:
      return {
        ...initialState,
        category: action.category
      }
    case SET_CATEGORY_FILTER:
      return {
        ...state,
        category: action.category
      }
    case SET_FREE_PRICE_FILTER:
      return {
        ...state,
        free: action.free
      }
    case SET_PAID_PRICE_FILTER:
      return {
        ...state,
        paid: action.paid
      }
    case SET_OPEN_SOURCE_FILTER:
      return {
        ...state,
        openSource: action.openSource
      }
    case SET_PROPIETARY_FILTER:
      return {
        ...state,
        propietary: action.propietary
      }
    default:
      return state
  }
}

export default catalogFilter
