import ReactGA from "react-ga";
import * as React from "react";


class ReactTrackedComponent<Props={}, State={}> extends React.Component<Props, State>{
        constructor(props: Props) {
            super(props)
        }

        componentDidMount(){
            ReactGA.pageview(window.location.pathname)
        }

        public fireEvent(category: string, action: string, label: string=""){
            ReactGA.event({
                category: category,
                action: action,
                label: label
            })
        }
}

export default ReactTrackedComponent
