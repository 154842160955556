import { push } from "connected-react-router"
import React from "react"
import { toast } from "react-toastify"
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import Comment from "../../@types/Comment"
import Post from "../../@types/Post"
import Routes from "../../@types/Routes"
import Tool from "../../@types/Tool"
import { deleteComment } from "../../services/CommentService"
import { deletePost } from "../../services/PostService"
import { deleteTool } from "../../services/ToolService"
import store from "../../stores/store"

interface Props {
  element: Tool | Post | Comment
  type: "tool" | "post" | "comment"
  reloadAfterDelete?: Boolean
  className?: string
  callbackFunction?: Function
}

interface State {
  modal: boolean
  name?: string
  type: string
  delete: Function
  callbackPath?: string
}

class ModalDelete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    switch (props.type) {
      case "tool":
        this.state = {
          modal: false,
          type: props.type,
          name: (props.element as Tool).name,
          delete: deleteTool,
          callbackPath: Routes.CATALOG
        }
        break
      case "post":
        this.state = {
          modal: false,
          type: props.type,
          name: (props.element as Post).title,
          delete: deletePost,
          callbackPath: Routes.POSTS
        }
        break
      case "comment":
        this.state = {
          modal: false,
          type: props.type,
          delete: deleteComment
        }
        break
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render() {
    return (
      <span>
        <Button
          color="danger"
          onClick={this.toggle}
          className={
            this.props.className ? this.props.className : "delete-button"
          }
        >
          Delete
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Delete {this.state.type}
          </ModalHeader>
          <ModalBody>
            The {this.state.type}
            {this.state.name ? ` "${this.state.name}"` : ""} will be deleted.
            Are you sure?
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => this.onDeleteToolClicked()}
              className="mr-2 delete-button"
            >
              Delete
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    )
  }

  private onDeleteToolClicked = async () => {
    try {
      await this.state.delete(this.props.element.id)
      this.toggle()
      toast.success(`The ${this.state.type} has been deleted!`)
      if (this.props.reloadAfterDelete) {
        window.location.reload()
      } else if (this.state.callbackPath) {
        store.dispatch(push(this.state.callbackPath))
      } else if (this.props.callbackFunction) {
        this.props.callbackFunction()
      }
    } catch (error) {
      console.error(error)
      toast.error(`Something went wrong, please try again`)
    }
  }
}

export default ModalDelete
