import * as React from "react"
import { connect } from "react-redux"
import { FormGroup, Input, Label } from "reactstrap"
import {
  setOpenSourceFilter,
  setPropietaryFilter
} from "../../actions/catalogFilter"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"

const OPEN_SOURCE = "openSource"
const PROPIETARY = "propietary"

interface Props {
  openSource: boolean
  propietary: boolean
  selectOpenSource: Function
  selectPropietary: Function
}

class LicenseSearchBox extends ReactTrackedComponent<Props> {
  public render = () => (
    <div>
      <Label for="price" className="pt-3 pb-1">
        License
      </Label>
      <FormGroup className="checkbutton-form-group mb-0">
        <Input
          name="license"
          value={OPEN_SOURCE}
          type="checkbox"
          checked={this.props.openSource}
          className="filter-input-checkbox"
          onChange={this.onChangeLicenseHandler}
        />
        <Label for="license" className="filter-input-label">
          Open source
        </Label>
      </FormGroup>
      <FormGroup className="checkbutton-form-group">
        <Input
          name="license"
          value={PROPIETARY}
          type="checkbox"
          checked={this.props.propietary}
          className="filter-input-checkbox"
          onChange={this.onChangeLicenseHandler}
        />
        <Label for="license" className="filter-input-label">
          Propietary
        </Label>
      </FormGroup>
    </div>
  )

  private onChangeLicenseHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value == OPEN_SOURCE) {
      this.props.selectOpenSource(event.target.checked)
    }
    if (event.target.value == PROPIETARY) {
      this.props.selectPropietary(event.target.checked)
    }
    this.fireEvent("Searcher", "License search", event.target.value)
  }
}

const mapStateToProps = (state: any) => ({
  openSource: state.catalogFilter.openSource,
  propietary: state.catalogFilter.propietary
})

const mapDispatchToProps = (dispatch: any) => ({
  selectOpenSource: (checked: boolean) =>
    dispatch(setOpenSourceFilter(checked)),
  selectPropietary: (checked: boolean) => dispatch(setPropietaryFilter(checked))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseSearchBox)
