const authentication = (state = { auth: null }, action: any) => {
  switch (action.type) {
    case "UPDATE_AUTHENTICATION_STATUS":
      return {
        auth: action.auth
      }
    default:
      return state
  }
}
export default authentication
