export const CLEAN_CATALOG_FILTER = "cleanCatalogFilter"
export const SET_SEARCH_STRING_FILTER = "setSearchStringFilter"
export const SET_SEARCH_STRING_AND_CLEAN_OTHER_FILTERS =
  "setSearchStringAndCleanOtherFilters"
export const SET_CATEGORY_AND_CLEAN_OTHER_FILTERS =
  "setCategoryAndCleanOtherFilters"
export const SET_CATEGORY_FILTER = "setCategoryFilter"
export const SET_FREE_PRICE_FILTER = "setFreePriceFilter"
export const SET_PAID_PRICE_FILTER = "setPaidPriceFilter"
export const SET_OPEN_SOURCE_FILTER = "setOpenSourceFilter"
export const SET_PROPIETARY_FILTER = "setPropietaryFilter"

export function cleanCatalogFilter() {
  return {
    type: CLEAN_CATALOG_FILTER
  }
}

export function setSearchStringAndCleanOtherFilters(searchString: string) {
  return {
    type: SET_SEARCH_STRING_AND_CLEAN_OTHER_FILTERS,
    searchString
  }
}

export function setSearchStringFilter(searchString: string) {
  return {
    type: SET_SEARCH_STRING_FILTER,
    searchString
  }
}

export function setCategoryAndCleanOtherFilters(category: string) {
  return {
    type: SET_CATEGORY_AND_CLEAN_OTHER_FILTERS,
    category
  }
}

export function setCategoryFilter(category: string) {
  return {
    type: SET_CATEGORY_FILTER,
    category
  }
}

export function setFreePriceFilter(free: boolean) {
  return {
    type: SET_FREE_PRICE_FILTER,
    free
  }
}

export function setPaidPriceFilter(paid: boolean) {
  return {
    type: SET_PAID_PRICE_FILTER,
    paid
  }
}

export function setOpenSourceFilter(openSource: boolean) {
  return {
    type: SET_OPEN_SOURCE_FILTER,
    openSource
  }
}

export function setPropietaryFilter(propietary: boolean) {
  return {
    type: SET_PROPIETARY_FILTER,
    propietary: propietary
  }
}
