import { push } from "connected-react-router"
import * as React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import Routes from "../../@types/Routes"
import store from "../../stores/store"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import PostFilter from "../molecules/PostFilter"
import FilterPostList from "../organisms/FilterPostList"
import ReactTrackedComponent from "./ReactTrackedComponent"
import { getAuthState } from "../../util/AuthenticationUtils"

interface Props {
  match: any
}

class PostsPage extends ReactTrackedComponent<Props> {
  public render = () => {
    return (
      <Container className="posts-page-container">
        {process.env.REACT_APP_STAGE != "prod" && <NoIndexHelmet />}
        <Row>
          <Col md={{ size: "3" }}>
            <h4 className="post-bar-title">Filter</h4>
            <PostFilter />
          </Col>
          <Col md={{ size: "9" }}>
            <Row>
              <Col xs={"auto"}>
                <h4 className="posts-title">Posts</h4>
              </Col>
              <Col>{this.renderDraftButton()}</Col>
            </Row>
            <FilterPostList username={this.props.match.params.username} />
          </Col>
        </Row>
      </Container>
    )
  }

  private renderDraftButton = () => {
    const authState = getAuthState()
    return (
      <div className="post-draft-button-container">
        {authState && authState.isAuthenticated && (
          <Button
            color="warning"
            onClick={() =>
              store.dispatch(
                push({
                  pathname: Routes.POST_DRAFTS
                })
              )
            }
            className="add-form-button"
          >
            Drafts
          </Button>
        )}
      </div>
    )
  }
}

export default PostsPage
