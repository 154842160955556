import React from "react"
import { Card, CardBody } from "reactstrap"
import CategoryGridItemLabel from "../atoms/CategoryGridItemLabel"
import { Category } from "../../@types/Category"

interface Props {
  category: Category
  enabled: boolean
}

const CategoryGridItem = (props: Props) => {
  return (
    <Card className="category-grid-item-container">
      <CardBody className="category-grid-item-body">
        <CategoryGridItemLabel
          enabled={props.enabled}
          category={props.category}
          imagePath={
            props.enabled
              ? "images/folder_purple.svg"
              : "images/folder_gray.svg"
          }
        />
      </CardBody>
    </Card>
  )
}

export default CategoryGridItem
