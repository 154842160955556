import React from "react"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import SearchBanner from "../molecules/SearchBanner"
import ReactTrackedComponent from "./ReactTrackedComponent"

class Homepage extends ReactTrackedComponent<any> {
  public render = () => (
    <React.Fragment>
      {process.env.REACT_APP_STAGE != "prod" && <NoIndexHelmet />}
      <SearchBanner />
    </React.Fragment>
  )
}

export default Homepage
