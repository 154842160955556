import React from "react"
import { Container, Col, Row } from "reactstrap"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"

class FeedbackBanner extends ReactTrackedComponent {
  public render = () => (
    <Container className="feedback-banner-container">
      <Row>
        <Col xl={{ size: 3, offset: 2 }} lg={{ size: 5, offset: 1 }} md="6">
          <img src="/images/feedback.svg" className="feedback-banner-image" />
          <div className="feedback-banner-title">
            Give us
            <br />
            Feedback!
          </div>
          <div className="feedback-banner-content">
            We really appreciate your feedback. Drop us a line to: <br />
            <a href="mailto:feedback@booster.cloud">
              feedback@booster.cloud
            </a>
          </div>
        </Col>
        <Col xl={{ size: 6, offset: 1 }} lg={{ size: 6 }} md="6">
          <img
            src="/images/contributor.svg"
            className="feedback-banner-image"
          />
          <div className="feedback-banner-title">
            Do you want to
            <br />
            become a contributor?
          </div>
          <div className="feedback-banner-content">
            If you want to add new tools to the platform, send us an email at:
            <br />
            <a href="mailto:contribute@booster.cloud">
              contribute@booster.cloud
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default FeedbackBanner
