import * as React from "react"
import { Col, Row } from "reactstrap"
import CategorySelect from "../atoms/CategorySelect"
import LicenseSearchBox from "../atoms/LicenseSearchBox"
import PriceSearchBox from "../atoms/PriceSearchBox"
import CatalogSearchBar from "../atoms/CatalogSearchBar"

const ToolFilter = () => (
  <Row>
    <Col xs="12">
      <CatalogSearchBar />
    </Col>
    <Col xs="12">
      <CategorySelect />
    </Col>
    <Col xs="6" md="12">
      <PriceSearchBox />
    </Col>
    <Col xs="6" md="12" className="mb-30">
      <LicenseSearchBox />
    </Col>
  </Row>
)

export default ToolFilter
