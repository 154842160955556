import { Storage } from 'aws-amplify';
import uuid from "uuid"

const imagePath = "images/"
const bucketName = process.env.REACT_APP_IMAGES_S3_BUCKET as string
const validFormats = ["image/png", "image/jpg", "image/jpeg"]
const maxSize = 10485760 // 10MB

export const uploadImage = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileName = generateUniqueFileName(file.name)
    const validationErrors = validateFile(file)
    if (validationErrors) {
      reject(validationErrors)
    }

    Storage.put(imagePath + fileName, file, {
      acl: "public-read",
      resumable: true,
      completeCallback: (event) => {
        console.log(`Successfully uploaded ${event.key}`);
        resolve(generateImageUrl(fileName))
      },
      errorCallback: (error) => {
        console.error('Unexpected error while uploading', error);
        reject(error)
      }
    });
  })
}

const generateUniqueFileName = (fileName: string): string =>
  uuid.v4() + getFileExtensionFrom(fileName)

const getFileExtensionFrom = (fileName: string): string =>
  "." + fileName.split(".").pop() || ""

const generateImageUrl = (fileName: string): string =>
  `https://${bucketName}.s3.amazonaws.com/${imagePath}${fileName}`

const validateFile = (file: File): string => {
  const errors = []
  if (file.size > maxSize) {
    errors.push("Image too large.")
  }

  if (!validFormats.includes(file.type)) {
    errors.push("Invalid format.")
  }

  return errors.join(" ")
}
