import { push } from "connected-react-router"
import React from "react"
import { connect } from "react-redux"
import { Button, Container, Input, InputGroup, InputGroupAddon, Col, Row } from "reactstrap"
import Routes from "../../@types/Routes"
import { setSearchStringAndCleanOtherFilters } from "../../actions/catalogFilter"
import CategoryDropdown from "../../components/atoms/CategoryDropdown"
import store from "../../stores/store"
import ReactTrackedComponent from "../pages/ReactTrackedComponent"

interface Props {
  selectSearchString: Function
  searchString: string
}

class SearchBanner extends ReactTrackedComponent<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render = () => (
    <Container className="search-banner-container">
      <Row>
        <Col lg={{ size: 4, offset: 2 }} md={{ size: 6, offset: 3 }}>
          <h3 className="search-banner-title">
            <span className="banner-title-brand">Rockets Platform </span>
            is a community driven catalog of building blocks for your software applications.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 3, offset: 2 }} md={{ size: 6, offset: 3 }}>
          <h6 className="banner-secondary-text">
            Search for tools, libraries, and services, or browse our categories to find exactly what you need.
          </h6>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 3, offset: 2 }} md={{ size: 6, offset: 3 }}>
          <InputGroup>
            <Input
              placeholder="Search the full catalog"
              onChange={this.setSearchString}
              onKeyPress={this.searchIfIsEnter}
            />
            <InputGroupAddon addonType="append" className="d-lg-none">
              <Button className="search-banner-button" onClick={this.search}>
                <img src="images/magnifier_white.png" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col
          lg={{ size: "auto", offset: 0 }}
          md={{ size: 6, offset: 3 }}
          className="search-banner-or"
        >
          Or
        </Col>
        <Col lg={{ size: 3, offset: 0 }} md={{ size: 6, offset: 3 }}>
          <CategoryDropdown />
        </Col>
      </Row>
    </Container>
  )

  private setSearchString = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.selectSearchString(event.target.value)
  }

  private searchIfIsEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.search()
    }
  }

  private search = () => {
    this.fireEvent(
      "Searcher",
      "Homepage search by name",
      this.props.searchString
    )
    store.dispatch(
      push({
        pathname: Routes.CATALOG
      })
    )
  }
}

const mapStateToProps = (state: any) => ({
  searchString: state.catalogFilter.searchString
})

const mapDispatchToProps = (dispatch: any) => ({
  selectSearchString: (filter: string) =>
    dispatch(setSearchStringAndCleanOtherFilters(filter))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBanner)
