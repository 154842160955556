import React, { Component } from "react"
import ReactGA from "react-ga"
import { Router, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AuthState from "../../@types/AuthState"
import Routes from "../../@types/Routes"
import UserGroups from "../../@types/UserGroups"
import { history } from "../../stores/store"
import "../../style/app.scss"
import Route from "../atoms/Route"
import ScrollToTop from "../atoms/ScrollToTop"
import { SecuredRoute } from "../atoms/SecuredRoute"
import CookieBanner from "../molecules/CookieBanner"
import FeedbackBanner from "../molecules/FeedbackBanner"
import Footer from "../molecules/Footer"
import Header from "../molecules/Header"
import AddPostPage from "./AddPostPage"
import AddToolPage from "./AddToolPage"
import CategoriesPage from "./CategoriesPage"
import EditPostPage from "./EditPostPage"
import EditToolPage from "./EditToolPage"
import ErrorPage from "./ErrorPage"
import ForgotPassword from "./ForgotPassword"
import Homepage from "./Homepage"
import Login from "./Login"
import PostDetailPage from "./PostDetailPage"
import PostsPage from "./PostsPage"
import ReviewOverviewPage from "./ReviewOverviewPage"
import SearchPage from "./SearchPage"
import SignUp from "./SignUp"
import ToolDetailPage from "./ToolDetailPage"
import PostDraftPage from "./PostDraftPage"

class App extends Component<any, AuthState> {
  constructor(props: any, state: AuthState) {
    super(props)

    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID as string)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render = () => (
    <Router history={history}>
      <div>
        <ScrollToTop>
          <CookieBanner />
          <Header />
          <div className="main-content">
            <ToastContainer />
            <Switch>
              <Route path={Routes.HOME} exact={true} component={Homepage} />
              <Route path={Routes.CATALOG} component={SearchPage} />
              <Route path={Routes.POSTS} exact={true} component={PostsPage} />
              <Route path={`${Routes.POSTS}/:username`} component={PostsPage} />
              <Route
                path={`${Routes.SHOW_TOOL}:id`}
                component={ToolDetailPage}
              />
              <Route
                path={`${Routes.SHOW_POST}:id`}
                component={PostDetailPage}
              />
              <SecuredRoute
                path={Routes.ADD_TOOL}
                component={AddToolPage}
                allowedRoles={{
                  roles: [UserGroups.ADMIN, UserGroups.CONTRIBUTOR]
                }}
              />
              <SecuredRoute
                path={`${Routes.EDIT_TOOL}:id`}
                component={EditToolPage}
                allowedRoles={{ roles: [UserGroups.ADMIN] }}
              />
              <SecuredRoute
                path={Routes.REVIEW_TOOLS}
                component={ReviewOverviewPage}
                allowedRoles={{ roles: [UserGroups.ADMIN] }}
              />
              <SecuredRoute
                path={Routes.ADD_POST}
                component={AddPostPage}
                allowedRoles={{
                  roles: [UserGroups.ADMIN, UserGroups.CONTRIBUTOR]
                }}
              />
              <SecuredRoute
                path={`${Routes.EDIT_POST}:id`}
                component={EditPostPage}
                allowedRoles={{
                  roles: [UserGroups.ADMIN, UserGroups.CONTRIBUTOR]
                }}
              />
              <SecuredRoute
                path={Routes.POST_DRAFTS}
                component={PostDraftPage}
                allowedRoles={{
                  roles: [UserGroups.ADMIN, UserGroups.CONTRIBUTOR]
                }}
              />
              <Route path={Routes.LOGIN} component={Login} />
              <Route path={Routes.SIGNUP} component={SignUp} />
              <Route path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
              <Route path={Routes.CATEGORIES} component={CategoriesPage} />
              <Route component={ErrorPage} />
            </Switch>
          </div>

          <FeedbackBanner />
          <Footer />
        </ScrollToTop>
      </div>
    </Router>
  )
}

export default App
