import React from "react"
import SimpleMDE from "react-simplemde-editor"
import "easymde/dist/easymde.min.css"
import { uploadImage } from "../../services/ImageService"

interface Props {
  onChange: (value: string) => any
  value: string
}

class MarkdownEditor extends React.Component<Props> {
  public render = () => (
    <SimpleMDE
      value={this.props.value}
      onChange={this.props.onChange}
      options={{
        autofocus: false,
        spellChecker: false,
        showIcons: [
          "strikethrough",
          "heading",
          "heading-smaller",
          "heading-bigger",
          "heading-1",
          "heading-2",
          "heading-3",
          "clean-block",
          "code",
          "table",
          "redo",
          "undo",
          "horizontal-rule"
        ],
        uploadImage: true,
        imageUploadFunction: this.imageUploadFunction
      }}
    />
  )

  private imageUploadFunction = async (
    file: File,
    onSuccess: Function,
    onError: Function
  ) => {
    try {
      const imageUrl = await uploadImage(file)
      onSuccess(imageUrl)
    } catch (error) {
      console.error(error)
      onError(error)
    }
  }
}

export default MarkdownEditor
