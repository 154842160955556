import * as React from "react"
import { Helmet } from "react-helmet"

const NoIndexHelmet = (props: any) => (
  <Helmet>
    <meta name="robots" content="noindex, nofollow" />
  </Helmet>
)

export default NoIndexHelmet
