import * as React from "react"
import { Col, Row } from "reactstrap"
import PostSearchBar from "../atoms/PostSearchBar"

const PostFilter = () => (
  <Row>
    <Col xs="12">
      <PostSearchBar />
    </Col>
  </Row>
)

export default PostFilter
