import * as React from "react"
import { Col, Row } from "reactstrap"
import Post from "../../@types/Post"
import { PostFilter } from "../../@types/PostFilter"
import Tool from "../../@types/Tool"
import { getPosts } from "../../services/PostService"
import ToolPost from "../atoms/ToolPost"

interface Props {
  tool: Tool
}

interface State {
  posts: Post[]
}

class ToolPosts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    this.getPosts()
  }

  render = () =>
    this.state.posts.length > 0 && (
      <div className="mt-30">
        <h4 className="posts-title">Posts</h4>
        {this.state.posts.map(post => this.renderPost(post))}
      </div>
    )

  private renderPost = (post: Post) => {
    return (
      <Row key={post.id}>
        <Col xs="12">
          <ToolPost post={post} />
        </Col>
      </Row>
    )
  }

  private getPosts = async () => {
    try {
      const filter: PostFilter = { text: this.props.tool.name, visible: true }
      const posts = await getPosts(filter)
      this.setState({ posts: posts.data })
    } catch (error) {
      console.log(error)
    }
  }
}

export default ToolPosts
