import * as React from "react"
import { Card, CardText } from "reactstrap"

export enum MessageType {
  ERROR = "Error",
  INFO = "Info"
}

interface Props {
  type: MessageType
  children: React.ReactNode
}

const Message = (props: Props) => (
  <Card className={"border " + getBorderClass(props.type)}>
    <CardText className="p-4">
      <code className={"mw-100 ww-break-word " + getTextClass(props.type)}>
        {props.children}
      </code>
    </CardText>
  </Card>
)

const getBorderClass = (type: MessageType) => {
  switch (type) {
    case MessageType.ERROR:
      return "border-danger"
    case MessageType.INFO:
      return "border-info"
    default:
      return ""
  }
}

const getTextClass = (type: MessageType) => {
  switch (type) {
    case MessageType.ERROR:
      return "text-danger"
    case MessageType.INFO:
      return "text-info"
    default:
      return ""
  }
}

export default Message
