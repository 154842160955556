import React from "react"
import { Spinner } from "reactstrap"
import Post from "../../@types/Post"
import { getPost } from "../../services/PostService"
import ErrorMessage from "../atoms/ErrorMessage"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ReactTrackedComponent from "./ReactTrackedComponent"
import PostDetail from "../molecules/PostDetail"

interface Props {
  match: any
}

interface State {
  post: Post | null
  isLoading: boolean
}

class PostDetailPage extends ReactTrackedComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      post: null,
      isLoading: true
    }
  }

  componentDidMount() {
    this.getPostDetail()
  }

  public render = () => {
    return (
      <div>
        <NoIndexHelmet />
        {this.state.isLoading ? (
          <Spinner className="spinner" type="grow" />
        ) : this.state.post ? (
          <PostDetail post={this.state.post} />
        ) : (
          <ErrorMessage>Post not found</ErrorMessage>
        )}
      </div>
    )
  }

  private getPostDetail = async () => {
    const postId = this.props.match.params.id
    try {
      const postResponse = await getPost(postId)
      this.setState({
        post: postResponse ? postResponse.data : null,
        isLoading: false
      })
    } catch (err) {
      console.log(err)
      this.setState({ isLoading: false })
    }
  }
}

export default PostDetailPage
