import * as React from "react"
import { Input } from "reactstrap"
import { InputType} from "reactstrap/lib/Input"

interface Props {
  autoFocus?: boolean
  required?: boolean
  disabled?: boolean
  placeholder: string
  name: string
  type: InputType
  value: string
  onChangeCallback: any
}

const AuthInput = (props: Props) => {
  return (
    <div className={props.required ? "required-input" : ""}>
      <Input
        autoFocus={props.autoFocus ? true : false}
        disabled={props.disabled ? true : false}
        name={props.name}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChangeCallback}
      />
    </div>
  )
}

export default AuthInput
