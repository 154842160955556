import { mapOption } from "fp-ts/lib/Array"
import { fromNullable } from "fp-ts/lib/Option"

export const doNothing = (): void => {
  return
}

/**
 * Returns the 'items' array, removing everything that is null
 * @param a nullable field that can contain an items field
 */
export const getItems = <A extends { items?: Array<B | null | undefined> }, B>(
  a?: A
): B[] =>
  fromNullable(a)
    .chain(x => fromNullable(x.items))
    .map(x => mapOption(x, fromNullable))
    .getOrElse([])

export const formatDate = (originalDate: string) => {
  const date = new Date(originalDate)
  return date.toLocaleDateString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  })
}
