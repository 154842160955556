import * as React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"
import Routes from "../../@types/Routes"
import { generateTagId, Tag } from "../../@types/Tag"

interface Props {
  tag: Tag
}

const PostTag = (props: Props): JSX.Element => {
  return props.tag.id != generateTagId(props.tag.text) ? (
    <Link to={Routes.SHOW_TOOL + props.tag.id} target="_blank">
      <Badge color="primary">{props.tag.text}</Badge>
    </Link>
  ) : (
    <Badge color="primary">{props.tag.text}</Badge>
  )
}

export default PostTag
