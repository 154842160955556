import * as React from "react"
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap"
import { formatDate } from "../../util/Function"
import Post from "../../@types/Post"
import { Link } from "react-router-dom"
import Routes from "../../@types/Routes"

interface Props {
  post: Post
}

const ToolPost = (props: Props) => (
  <Card className="post-card">
    <CardBody>
      <CardTitle className="h6 post-card-title">
        <div className="dv-title comment-dv-title">
          <label className="comment-item-title">{props.post.userName}</label>
          <label className="comment-item-title-date">
            {`- ${formatDate(props.post.createdAt)}`}
          </label>
        </div>
      </CardTitle>
      <div className="card-comment-body">
        <Row noGutters>
          <Col md={"auto"}>
            <div className="post-props-container">
              <Link
                to={Routes.SHOW_POST + props.post.id}
                className="card-post-title-link"
                target="_blank"
              >
                {props.post.title}
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </CardBody>
  </Card>
)

export default ToolPost
