import { push } from "connected-react-router"
import * as React from "react"
import { Link } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"
import Post from "../../@types/Post"
import Routes from "../../@types/Routes"
import store from "../../stores/store"
import { getAuthState, isAdmin } from "../../util/AuthenticationUtils"
import { formatDate } from "../../util/Function"
import Markdown from "../../util/Markdown"
import ModalDelete from "../atoms/ModalDelete"
import PostTag from "../atoms/PostTag"

interface Props {
  post: Post
}

class PostDetail extends React.Component<Props> {
  public render = () => (
    <Container className="post-view-container">
      <div className="my-4">
        <Row>
          <Col>
            <img
              className="tool-back-to-search-img"
              src="/images/back_arrow.svg"
            />
            <Link to={Routes.POSTS}>Back to search</Link>
          </Col>
        </Row>
        <Row className="mt-30">
          <Col
            className="tool-view-descriptions"
            md={{ size: 6, order: 2 }}
            xs={{ order: 1 }}
          >
            <div className="post-view-title">{this.props.post.title}</div>
            <Markdown>{this.props.post.body}</Markdown>
          </Col>
          <Col md={{ size: 3, offset: 3, order: 1 }} xs={{ order: 2 }}>
            <div className="post-props-container">
              <h6 className="post-props-title">Author</h6>
              <p className="post-props-value">
                <Link to={`${Routes.POSTS}/${this.props.post.userName}`}>
                  {this.props.post.userName}
                </Link>
              </p>
            </div>
            <div className="post-props-container">
              <h6 className="post-props-title">Created</h6>
              <p className="post-props-value">
                {formatDate(this.props.post.createdAt)}
              </p>
            </div>
            <div className="post-props-container">
              <h6 className="post-props-title">Tags</h6>
              <p className="post-props-value">
                {this.props.post.tags.map(tag => (
                  <PostTag tag={tag} key={tag.id} />
                ))}
              </p>
            </div>
            {this.renderActionButtons(this.props.post)}
          </Col>
        </Row>
      </div>
    </Container>
  )

  private renderActionButtons = (post: Post) => {
    const authState = getAuthState()

    return (
      <div>
        {authState &&
          authState.isAuthenticated &&
          authState.username == post.userName && (
            <Button
              color="secondary"
              onClick={() =>
                store.dispatch(
                  push({
                    pathname: Routes.EDIT_POST + post.id
                  })
                )
              }
              className="add-form-button mr-2"
            >
              Edit
            </Button>
          )}
        {((authState &&
          authState.isAuthenticated &&
          authState.username == post.userName) ||
          isAdmin(authState)) && <ModalDelete element={post} type="post" />}
      </div>
    )
  }
}

export default PostDetail
