import React from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"
import CatalogFilterState from "../../@types/CatalogFilterState"
import Tool from "../../@types/Tool"
import { ToolFilter } from "../../@types/ToolFilter"
import InfoMessage from "../atoms/InfoMessage"
import ReviewToolList from "../molecules/ReviewToolList"
import ToolList from "../molecules/ToolList"
import { getTools } from "../../services/ToolService"

interface Props {
  catalogFilterState: CatalogFilterState
  visible: boolean
}

interface State {
  tools: Tool[]
  isLoading: boolean
}

class FilterToolList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tools: [],
      isLoading: true
    }
  }

  componentDidMount() {
    this.getTools()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.catalogFilterState != this.props.catalogFilterState) {
      this.setState({ isLoading: true })
      this.getTools()
    }
  }

  render = () => (
    <div className="tool-grid">
      {this.state.isLoading ? (
        <Spinner className="spinner" type="grow" />
      ) : this.state.tools && this.state.tools.length > 0 ? (
        this.props.visible ? (
          <ToolList tools={this.state.tools} />
        ) : (
          <ReviewToolList tools={this.state.tools} />
        )
      ) : (
        <InfoMessage>No tools found</InfoMessage>
      )}
    </div>
  )

  private getTriStateFilterValue = (
    trueOption: boolean,
    falseOption: boolean
  ): boolean | null => (trueOption == falseOption ? null : trueOption)

  private getFilters = (): ToolFilter => ({
    category: this.props.catalogFilterState.category,
    free: this.getTriStateFilterValue(
      this.props.catalogFilterState.free,
      this.props.catalogFilterState.paid
    ),
    openSource: this.getTriStateFilterValue(
      this.props.catalogFilterState.openSource,
      this.props.catalogFilterState.propietary
    ),
    text: this.props.catalogFilterState.searchString,
    visible: this.props.visible
  })

  private getTools = () => {
    getTools(this.getFilters())
      .then(tools => {
        this.setState({ tools: tools.data, isLoading: false })
      })
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false })
      })
  }
}

const mapStateToProps = (state: any) => ({
  catalogFilterState: state.catalogFilter
})

export default connect(mapStateToProps)(FilterToolList)
