import axios from "axios"
import { PostFilter } from "../@types/PostFilter"
import { getJwtToken } from "../util/AuthenticationUtils"

const baseUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/posts`

export const getPosts = async (filters: PostFilter) => {
  return axios({
    method: "get",
    url: baseUrl,
    headers: {
      "Content-Type": "application/json"
    },
    params: filters
  })
}

export const createPost = async (post: any) => {
  return axios({
    method: "post",
    url: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    data: post
  })
}

export const getPost = async (postId: string) => {
  return axios({
    method: "get",
    url: `${baseUrl}/${postId}`,
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export const updatePost = async (post: any) => {
  return axios({
    method: "put",
    url: `${baseUrl}/${post.id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    data: post
  })
}

export const deletePost = async (postId: string) => {
  return axios({
    method: "delete",
    url: `${baseUrl}/${postId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    }
  })
}
