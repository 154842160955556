import axios from "axios"
import { ToolFilter } from "../@types/ToolFilter"
import ToolRating from "../@types/ToolRating"
import { getJwtToken } from "../util/AuthenticationUtils"

export const deleteTool = async (toolId: string) => {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/tools/${toolId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    }
  })
}

export const getTools = async (filters: ToolFilter) => {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/tools`,
    headers: {
      "Content-Type": "application/json"
    },
    params: filters
  })
}

export const getTool = async (toolId: string) => {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/tools/${toolId}`,
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export const getToolRating = async (toolId: string) => {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/ratings`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    params: {
      toolId: toolId
    }
  })
}

export const createTool = async (tool: any) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/tools`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    data: tool
  })
}

export const updateTool = async (tool: any) => {
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/tools/${tool.id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    data: tool
  })
}

export const updateToolRating = async (toolRating: ToolRating) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_AWS_API_GATEWAY}/ratings`,
    headers: {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    },
    data: toolRating
  })
}
