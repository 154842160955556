import { fromNullable } from "fp-ts/lib/Option"
import * as React from "react"
import { Col, Row } from "reactstrap"
import Tool from "../../@types/Tool"
import ErrorMessage from "../atoms/ErrorMessage"
import ReviewToolItem from "../atoms/ReviewToolItem"

interface Props {
  tools: Tool[]
}

class ReviewToolList extends React.Component<Props> {
  public render = () => (
    <Row className={"equal-height-cards"}>
      {fromNullable(this.props.tools)
        .map(tools => tools.map(this.renderTool))
        .getOrElse([renderError])}
    </Row>
  )

  private renderTool = (tool: Tool) => (
    <Col xl={{ size: 6 }} key={tool.id}>
      <ReviewToolItem tool={tool} />
    </Col>
  )
}

const renderError = <ErrorMessage>Could not load tools</ErrorMessage>

export default ReviewToolList
