import * as React from "react"
import AddComment from "../atoms/AddComment"
import Comment from "../../@types/Comment"
import CommentList from "../molecules/CommentList"
import Tool from "../../@types/Tool"
import { getAuthState } from "../../util/AuthenticationUtils"
import { getComments } from "../../services/CommentService"

interface Props {
  tool: Tool
}

interface State {
  comments: Comment[]
}

class ToolComments extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      comments: []
    }
  }

  componentDidMount() {
    this.getComments()
  }

  public render = () => {
    const authState = getAuthState()
    return (
      <div>
        {((authState && authState.isAuthenticated) ||
          this.state.comments.length > 0) && (
          <div className="other-comments-title">Comments</div>
        )}
        {authState && authState.isAuthenticated && (
          <div className="comment-container">
            <AddComment
              toolId={this.props.tool.id}
              onCommentAdded={this.addComment}
            />
          </div>
        )}
        <div>
          <CommentList
            comments={this.state.comments}
            onDelete={this.deleteComment}
            onUpdate={this.updateComment}
          />
        </div>
      </div>
    )
  }

  private getComments = async () => {
    try {
      const comments = await getComments(this.props.tool.id)
      this.setState({
        comments: comments.data
      })
    } catch (error) {
      console.error(error)
    }
  }

  private deleteComment = (commentId: string) => {
    const comments = this.state.comments.filter(
      comment => comment.id != commentId
    )
    this.setState({ comments: comments })
  }

  private addComment = (comment: Comment) => {
    const comments = [comment, ...this.state.comments]
    this.setState({
      comments: comments
    })
  }

  private updateComment = (commentId: string, message: string) => {
    const comments = this.state.comments.map(comment => {
      if (comment.id == commentId) {
        comment.message = message
      }
      return comment
    })
    this.setState({
      comments: comments
    })
  }
}

export default ToolComments
