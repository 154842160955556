import { CognitoUser } from "amazon-cognito-identity-js"
import { Auth } from "aws-amplify"
import { push } from "connected-react-router"
import { Lens } from "monocle-ts"
import * as React from "react"
import { Link } from "react-router-dom"
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row
} from "reactstrap"
import Routes from "../../@types/Routes"
import store from "../../stores/store"
import AuthInput from "../atoms/AuthInput"
import NoIndexHelmet from "../atoms/NoIndexHelmet"
import ReactTrackedComponent from "./ReactTrackedComponent"

interface State {
  username: string
  email: string
  password: string
  repeatPassword: string
  error: string
  cognitoUser?: CognitoUser
  code: string
  toVerificationCode: boolean
  newCodeSent: boolean
  toLogin: boolean
  toLoginMessage: string
}

class SignUp extends ReactTrackedComponent<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      username: this.props.location.state
        ? this.props.location.state.username
        : "",
      email: "",
      password: "",
      repeatPassword: "",
      error: "",
      code: "",
      toVerificationCode: this.props.location.state
        ? this.props.location.state.toVerificationCode
        : false,
      newCodeSent: false,
      toLogin: false,
      toLoginMessage: ""
    }
  }

  public render = () => {
    if (this.state.toLogin) {
      store.dispatch(
        push({
          pathname: Routes.LOGIN,
          state: { loginMessage: this.state.toLoginMessage }
        })
      )
    }

    return (
      <Container className="authentication-container">
        <NoIndexHelmet />
        {!this.state.cognitoUser && !this.state.toVerificationCode
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </Container>
    )
  }

  private renderForm = () => (
    <Row>
      <Col className="my-5 authentication-content" lg={{ size: 4, offset: 4 }}>
        <h3 className="authentication-title">
          <span className="authentication-title-action">Sign up</span>
        </h3>
        <Form
          className="authentication-form"
          onSubmit={this.handleSignUp}
          autoComplete="never"
        >
          <FormGroup>
            <AuthInput
              autoFocus={true}
              required={true}
              placeholder="Choose your username"
              name="username"
              type="text"
              value={this.state.username}
              onChangeCallback={this.handleChange}
            />
            <AuthInput
              required={true}
              placeholder="Enter your email"
              name="email"
              type="text"
              value={this.state.email}
              onChangeCallback={this.handleChange}
            />
            <AuthInput
              required={true}
              placeholder="Password"
              name="password"
              type="password"
              value={this.state.password}
              onChangeCallback={this.handleChange}
            />
            <AuthInput
              required={true}
              placeholder="Repeat Password"
              name="repeatPassword"
              type="password"
              value={this.state.repeatPassword}
              onChangeCallback={this.handleChange}
            />
            <Label className="required-message">(*) Required fields</Label>
          </FormGroup>
          <Alert color="danger" isOpen={this.state.error.length > 0}>
            {this.state.error}
          </Alert>
          <Button block className="authentication-button" type="submit">
            Sign up
          </Button>
          <div className="authentication-footer">
            Already have an account? <Link to={Routes.LOGIN}>Log in</Link>
          </div>
        </Form>
      </Col>
    </Row>
  )

  private renderConfirmationForm = () => (
    <Row>
      <Col className="my-5 authentication-content" lg={{ size: 4, offset: 4 }}>
        <Alert color="success">
          {this.state.toVerificationCode && !this.state.newCodeSent
            ? "Please verify your account"
            : "A verification code has been sent to your email"}
        </Alert>
        <Form onSubmit={this.handleConfirmation} autoComplete="never">
          <FormGroup>
            <AuthInput
              autoFocus={true}
              placeholder="Verification code"
              name="code"
              type="text"
              value={this.state.code}
              onChangeCallback={this.handleChange}
            />
          </FormGroup>
          <Alert color="danger" isOpen={this.state.error.length > 0}>
            {this.state.error}
          </Alert>
          <Button block className="authentication-button" type="submit">
            Verify
          </Button>
          <Button block type="button" onClick={() => this.resendCode()}>
            {this.state.toVerificationCode && !this.state.newCodeSent
              ? "Request verification code"
              : "Resend code"}
          </Button>
        </Form>
      </Col>
    </Row>
  )

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setField(event.target.name as keyof State, event.target.value)

  private handleSignUp = (event: any) => {
    event.preventDefault()
    if (this.validateForm()) {
      Auth.signUp(this.state.username, this.state.password, this.state.email)
        .then(data => {
          this.setState({
            username: this.state.username,
            password: this.state.password,
            repeatPassword: "",
            error: "",
            cognitoUser: data.user,
            email: "",
            code: ""
          })
        })
        .catch(err => {
          this.setField("error", err.message)
        })
    }
    this.fireEvent("Authentication", "Signed up")
  }

  private handleConfirmation = (event: any) => {
    event.preventDefault()
    Auth.confirmSignUp(this.state.username, this.state.code)
      .then(data => {
        this.setState({
          toLogin: true,
          toLoginMessage: "User has been successfully verified"
        })
      })
      .catch(err => {
        if (err && err.message) {
          this.setField("error", err.message)
        } else {
          this.setField("error", err)
        }
      })
    this.fireEvent("Authentication", "Code verified")
  }

  private resendCode = () => {
    Auth.resendSignUp(this.state.username)
      .then(() => {
        console.log("code resent successfully")
        this.setState({
          error: "",
          newCodeSent: true
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  private setField = (field: keyof State, value: any): void => {
    const fieldAccessor = Lens.fromProp<State>()(field as keyof State)
    this.setState(fieldAccessor.set(value))
  }

  private validateForm = (): boolean => {
    if (this.state.username.trim().length === 0) {
      this.setField("error", "Username is required")
      return false
    }
    if (this.state.email.trim().length === 0) {
      this.setField("error", "Email is required")
      return false
    }
    if (this.state.password.trim().length === 0) {
      this.setField("error", "Password is required")
      return false
    }
    if (this.state.repeatPassword.trim().length === 0) {
      this.setField("error", "Please repeat the password")
      return false
    }
    if (this.state.password !== this.state.repeatPassword) {
      this.setField("error", "The passwords are different")
      return false
    }
    return true
  }
}

export default SignUp
