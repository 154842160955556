import { routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"
import { applyMiddleware, compose, createStore } from "redux"
import rootReducer from "../reducers"

export const history = createBrowserHistory()

const store = createStore(
  rootReducer(history),
  {},
  compose(applyMiddleware(routerMiddleware(history)))
)

export default store
