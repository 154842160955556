import Markdown from "markdown-it"
import React from "react"
import stripIndent from "strip-indent"

export default class extends React.Component {
  static defaultProps = {
    container: "div",
    options: {}
  }

  render() {
    var Container = this.props.container
    return (
      <Container className="markdown-container">{this.content()}</Container>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.md = new Markdown(this.props.options)
    }
  }

  content() {
    if (this.props.source) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: this.renderMarkdown(this.props.source)
          }}
        />
      )
    } else {
      return React.Children.map(this.props.children, child => {
        if (typeof child === "string") {
          return (
            <span
              dangerouslySetInnerHTML={{ __html: this.renderMarkdown(child) }}
            />
          )
        } else {
          return child
        }
      })
    }
  }

  renderMarkdown(source) {
    if (!this.md) {
      this.md = new Markdown(this.props.options)
    }
    return this.md.render(stripIndent(source))
  }
}
