import React from "react"
import { postComment } from "../../services/CommentService"
import { Button, Input, Label, Col, Form, Row } from "reactstrap"
import { Lens } from "monocle-ts"
import { toast } from "react-toastify"

interface Props {
  toolId: string
  onCommentAdded: Function
}

interface State {
  comment: string
}

class AddComment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      comment: ""
    }
  }

  render = () => {
    return (
      <Form>
        <Row>
          <Col
            md={{ size: 12, order: 3 }}
            xs={{ order: 1 }}
            className="add-form-text-fields-container"
          >
            <Label for="comment">Leave your comment</Label>
            <Input
              name="comment"
              type="textarea"
              value={this.state.comment}
              onChange={this.handleOnTextChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, order: 2 }} xs={{ order: 1 }}>
            <Button
              type="button"
              className="add-form-button btn"
              onClick={this.handleOnClick}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  private handleOnClick = () => {
    if (this.state.comment != "") {
      this.postComment()
    }
  }

  private handleOnTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const fieldAccessor = Lens.fromProp<State>()(event.target
      .name as keyof State)
    this.setState(fieldAccessor.set(value))
  }

  private postComment = async () => {
    try {
      const comment = await postComment(this.getCommentItem())
      this.setState({ comment: "" })
      this.showSuccessMessage()
      this.props.onCommentAdded(comment.data)
    } catch (error) {
      console.log(error)
      this.setState({ comment: "" })
      this.showErrorMessage()
    }
  }

  private getCommentItem = () => {
    return {
      toolId: this.props.toolId,
      message: this.state.comment
    }
  }

  private showSuccessMessage = () => {
    toast.success("The comment has been sent!")
  }

  private showErrorMessage = () => {
    toast.error("Something went wrong, please try again")
  }
}

export default AddComment
