import * as React from "react"
import { Link } from "react-router-dom"
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from "reactstrap"
import Routes from "../../@types/Routes"
import { cleanCatalogFilter } from "../../actions/catalogFilter"
import { cleanPostFilter } from "../../actions/postFilter"
import store from "../../stores/store"
import { getAuthState, isAdmin } from "../../util/AuthenticationUtils"
import ActionLink from "../atoms/ActionLink"

interface State {
  isOpen: boolean
}

class Header extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  public render = () => {
    const authState = getAuthState()

    return (
      <div className="container-fluid header-container">
        <Navbar expand="md" className="navbar-container">
          <Link to={Routes.HOME} className="logo-link py-3">
            <img
              className="header-logo"
              src="/images/RocketPlatform_purple.png"
            />
          </Link>
          <NavbarToggler className="toggler" onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <ActionLink
                  className="display-5 nav-link"
                  to={Routes.CATALOG}
                  onClickFunctions={[
                    () => store.dispatch(cleanCatalogFilter())
                  ]}
                >
                  Catalog
                </ActionLink>
              </NavItem>
              <NavItem>
                <Link
                  className="display-5 nav-link"
                  to={Routes.CATEGORIES}
                >
                  Categories
                </Link>
              </NavItem>
              <NavItem>
                <ActionLink
                  className="display-5 nav-link"
                  to={Routes.POSTS}
                  onClickFunctions={[() => store.dispatch(cleanPostFilter())]}
                >
                  Posts
                </ActionLink>
              </NavItem>
              {isAdmin(authState) && (
                <NavItem>
                  <ActionLink
                    className="display-5 nav-link"
                    to={Routes.REVIEW_TOOLS}
                    onClickFunctions={[
                      () => store.dispatch(cleanCatalogFilter())
                    ]}
                  >
                    Review
                  </ActionLink>
                </NavItem>
              )}
              {authState && authState.isAuthenticated && (
                <NavItem>
                  <Link
                    className="display-5 nav-link"
                    to={Routes.ADD_TOOL}
                  >
                    Add tool
                  </Link>
                </NavItem>
              )}
              {authState && authState.isAuthenticated && (
                <NavItem>
                  <Link
                    className="display-5 nav-link"
                    to={Routes.ADD_POST}
                  >
                    Add post
                  </Link>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
}

export default Header
