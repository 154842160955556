import * as React from "react"
import { Col, Row } from "reactstrap"
import Comment from "../../@types/Comment"
import CommentListItem from "../atoms/CommentListItem"
interface Props {
  comments: Comment[]
  onDelete: Function
  onUpdate: Function
}

class CommentList extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render = () =>
    this.props.comments.map(comment => this.renderComment(comment))

  private renderComment = (comment: Comment) => {
    return (
      <Row key={comment.id}>
        <Col xs="12">
          <CommentListItem
            comment={comment}
            onDelete={this.props.onDelete}
            onUpdate={this.props.onUpdate}
          />
        </Col>
      </Row>
    )
  }
}

export default CommentList
